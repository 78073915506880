import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from 'components/MaterialDesign/menu';
import { actionValues } from './action';
import { currentLanguage } from 'i18n/config';

function DefaultColumnFilter({ column, filterOptionsByAccessor: defaultFilterOptionsByAccessor, selectedDate }) {
  const defaultToDisplayString = useCallback((a) => a, []);
  const {
    setFilter,
    filterValue = actionValues.Initial,
    toDisplayString = defaultToDisplayString,
    filterOptions = defaultFilterOptionsByAccessor,
  } = column;
  const [name, setName] = useState(toDisplayString(filterValue));
  useEffect(() => {
    setFilter(filterValue);
    setName(toDisplayString(filterValue));
  }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickItem = useCallback((item) => {
    setFilter(item.value);
    setName(item.name);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const items = useMemo(
    () =>
      filterOptions[column.id].map((e, i) => ({
        id: `${i + 1}`,
        name: `${toDisplayString(e)}`,
        value: `${e}`,
      })),
    [filterOptions, column] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Menu
      items={items}
      name={name}
      onClickItem={onClickItem}
      styles={{ width: currentLanguage === 'jp' ? 200 : null }}
    />
  );
}

DefaultColumnFilter.propTypes = {
  filterOptionsByAccessor: PropTypes.shape({}),
  column: PropTypes.shape({
    id: PropTypes.string,
    setFilter: PropTypes.func,
    filterValue: PropTypes.string,
    toDisplayString: PropTypes.func,
    filterOptions: PropTypes.shape({}),
  }),
  selectedDate: PropTypes.instanceOf(Date),
};
export default DefaultColumnFilter;
