import React, { useState, useEffect, memo } from 'react';
import styled from 'styled-components';
import { setProfile } from 'reducers/members';
import { useDispatch, useSelector } from 'react-redux';
import { dashboard_v2 } from 'theme';
import { fetchMember } from 'lib/memberService';
import { listSurveyByUser } from 'lib/surveyService';
import Button from 'components/Dashboard_v2/Button';
import Text from 'components/Dashboard_v2/Text';
import Modal from 'components/Dashboard_v2/Modal';
import LineMessageActiveIcon from 'components/icons/LineMessageActiveIcon';
import LineMessageInactiveIcon from 'components/icons/LineMessageInactiveIcon';
import LineMessageBlockedIcon from 'components/icons/LineMessageBlockedIcon';
import LinkIcon from 'components/icons/LinkIcon';
import UnlinkIcon from 'components/icons/UnlinkIcon';
import Overview from './components/Overview';
import PatientTrackingList from './components/PatientTrackingList';
import SurveyList from 'feature/MemberCenter/MemberProfile/components/SurveyList';
import MergeHistory from 'feature/MemberCenter/MemberProfile/components/MergeHistory';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Main = styled.div`
  display: flex;
  padding: 12px 30px 24px 30px;
  gap: 24px;
  margin-bottom: 40px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ width }) => width && `width: ${width}`};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
  ${({ overflow }) => overflow && `overflow: ${overflow}`};
  ${({ padding }) => padding && `padding: ${padding}`};
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  cursor: pointer;
  span {
    color: ${({ isSelected }) => (isSelected ? colors.SHADES_900 : colors.SHADES_500)};
  }
  i {
    padding: 4px 12px;
    color: ${dashboard_v2.colors.SHADES_100};
    background-color: ${dashboard_v2.colors.SHADES_600};
    border-radius: 100px;
    font-style: normal;
  }
  ${({ isSelected }) =>
    isSelected &&
    ` 
      background-color: ${dashboard_v2.colors.SHADES_100};
      border-radius: 8px;
    `}
`;

const Card = styled.div`
  ${({ width }) => width && `width: ${width}px`};
  ${({ height }) => height && `height: ${height}px`};
  ${({ padding }) => padding && `padding: ${padding}`};
  background: ${colors.SHADES_000};
  border: 1px solid ${colors.SHADES_300};
  border-radius: 8px;
  margin-bottom: 24px;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UserImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  background-color: ${colors.SHADES_200};
  border-radius: 100%;
  margin-bottom: 20px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  i {
    font-size: 70px;
    color: ${colors.SHADES_000};
  }
`;

const UserStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 20px;
`;

const CloseButtonWrapper = styled.div`
  /* position: absolute;
  right: 40px;
  bottom: 40px; */
  display: flex;
  justify-content: flex-end;
`;

export const getFormattedDate = (dataDate, type) => {
  if (!dataDate) return '';
  const hasTime = dataDate.includes(':');
  const date = new Date(dataDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const meridiem = hour >= 12 ? t('pm') : t('am');
  const hour12 = hour % 12 || 12;
  const weekDay = [t('day'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')][
    date.getDay()
  ];
  const formattedDate = t('formattedDate', { year, month, day, weekDay });
  const formattedTime = `${meridiem}${hour12}:${minute}`;
  if (type === 'patientTrackingLabel') {
    return `${year}/${month}/${day} ${hour}:${minute}`;
  }
  if (type === 'date') {
    return formattedDate;
  }
  if (type === 'time') {
    return formattedTime;
  }
  return hasTime ? `${formattedDate} ${formattedTime}` : formattedDate;
};

const LineBindingIcon = (value) => {
  switch (value) {
    case 'binding':
      return <LinkIcon size={28} tooltip />;
    case 'unbinding':
    default:
      return <UnlinkIcon size={28} tooltip />;
  }
};

const LineMessageIcon = (value) => {
  switch (value) {
    case 'active':
      return <LineMessageActiveIcon size={28} tooltip />;
    case 'blocked':
      return <LineMessageBlockedIcon size={28} tooltip />;
    case 'inactive':
    default:
      return <LineMessageInactiveIcon size={28} tooltip />;
  }
};

const MemberProfile = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.member.profile);
  const [clientId] = useSelector((state) => state.clients.selectedIds);
  const [selectedType, setSelectedType] = useState('overview');
  const [pictureUrl, setPictureUrl] = useState('');
  const [surveyList, setSurveyList] = useState([]);
  const [mergeHistory, setMergeHistory] = useState([]);
  const { row } = profile || {};
  const handleClose = () => {
    setSelectedType('overview');
    setPictureUrl('');
    dispatch(setProfile(null));
  };

  useEffect(() => {
    if (!row || pictureUrl) return;
    fetchMember({ clientId, memberId: row?.original?.id }).then((res) => {
      setPictureUrl(res.pictureUrl);
      setMergeHistory(res.mergeHistory.filter((item) => item.oldIDType !== 'lineID'));
    });
    listSurveyByUser({ clientId, userId: row?.original?.id }).then((res) => {
      setSurveyList(res);
    });
  }, [clientId, row, pictureUrl]);

  if (!profile) return null;

  const { original } = row;
  const { id, name, lineName, hisName, lineBinding, lineMessage, patientTrackings } = original;
  const content = () => {
    switch (selectedType) {
      case 'patientTracking':
        return <PatientTrackingList userId={id} />;
      case 'survey':
        return <SurveyList userId={id} surveyList={surveyList} />;
      case 'mergeHistory':
        return <MergeHistory list={mergeHistory} />;
      case 'overview':
      default:
        return <Overview />;
    }
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      title={t('memberInformationPage')}
      width="100%"
      maxWidth="1080px"
      padding="48px 20px 40px 40px"
      zIndex={1300}
    >
      <Main>
        <FlexColumn>
          <Card width={256} padding="40px 48px">
            <UserInfo>
              <UserImage>
                {pictureUrl ? <img src={pictureUrl} alt="user" /> : <i className="ri-user-3-fill" />}
              </UserImage>
              <UserStatus>
                {LineBindingIcon(lineBinding)}
                {LineMessageIcon(lineMessage)}
              </UserStatus>
              <Text font="Heading/H5/Medium">{name || hisName || lineName}</Text>
            </UserInfo>
          </Card>
          <MenuItem onClick={() => setSelectedType('overview')} isSelected={selectedType === 'overview'}>
            <Text font="Body/19px_Medium">{t('overview')}</Text>
          </MenuItem>
          <MenuItem onClick={() => setSelectedType('patientTracking')} isSelected={selectedType === 'patientTracking'}>
            <Text font="Body/19px_Medium">{t('patientTracking')}</Text>
            <Text font="Heading/X-Small/Medium" color="SHADES_500" as="i">
              {patientTrackings.length}
            </Text>
          </MenuItem>
          {surveyList.length > 0 && (
            <MenuItem onClick={() => setSelectedType('survey')} isSelected={selectedType === 'survey'}>
              <Text font="Body/19px_Medium">{t('survey')}</Text>
              <Text font="Heading/X-Small/Medium" color="SHADES_500" as="i">
                {surveyList.length}
              </Text>
            </MenuItem>
          )}
          {mergeHistory.length > 0 && (
            <MenuItem onClick={() => setSelectedType('mergeHistory')} isSelected={selectedType === 'mergeLog'}>
              <Text font="Body/19px_Medium">{t('mergeMember.logTitle')}</Text>
              <Text font="Heading/X-Small/Medium" color="SHADES_500" as="i">
                {mergeHistory.length}
              </Text>
            </MenuItem>
          )}
        </FlexColumn>
        <FlexColumn maxHeight="640px" width="100%" overflow="auto" padding="0 20px 0 0">
          {content()}
        </FlexColumn>
      </Main>
      <CloseButtonWrapper>
        <Button color="secondary" onClick={handleClose}>
          {t('closeWindow')}
        </Button>
      </CloseButtonWrapper>
    </Modal>
  );
};

export default memo(MemberProfile);
