import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';
import { dashboard_v2 } from 'theme';
import connectData from 'components/HOCs/connectData';

import SideBar from '../Sidebar';
import AllMembers from './AllMembers';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const tabItems = [
  { name: t('allMembers'), component: <AllMembers /> },
  // @TODO: hidden for later phase
  // { name: t('appointmentList'), component: <div>{t('appointmentList')}</div> },
  // { name: t('satisfactionList'), component: <div>{t('satisfactionList')}</div> },
];

const Container = styled.div`
  position: relative;
  margin: 0 -30px;
`;

const Main = styled.div`
  padding: 30px 30px 30px 86px;
`;

const Header = styled.div`
  padding: 5px 30px 0 30px;
  margin: -30px -30px 30px;
  background-color: #fff;
  border-bottom: 1px solid ${colors.SHADES_200};
`;

const SectionTitle = styled.h5`
  margin: 40px 0 24px 0;
  font-size: 28px;
`;

const Content = styled.div``;

const StyledTab = withStyles(() => ({
  root: {
    fontSize: '16px',
    color: (props) => (props.selected ? colors.PRIMARY_500 : colors.SHADES_500),
  },
}))(Tab);

const MemberList = ({ history }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const navItems = useSelector((state) => state.nav?.items);
  const onTabIndexChange = (e, index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    const hasMemberNav = Boolean(navItems.find((item) => item.url === '/members'));
    if (!hasMemberNav) {
      history.push('/home');
    }
  }, [navItems, history]);

  return (
    <Container className="animated fadeIn">
      <SideBar />
      <Main>
        <Header>
          <SectionTitle>{t('member')}</SectionTitle>
          <Tabs
            value={tabIndex}
            onChange={onTabIndexChange}
            TabIndicatorProps={{ style: { background: colors.PRIMARY_500 } }}
          >
            {tabItems.map((item, index) => (
              <StyledTab label={item.name} key={index} />
            ))}
          </Tabs>
        </Header>
        <Content>
          {tabItems.map((item, index) => (
            <div value={index} hidden={index !== tabIndex} key={index}>
              {item.component}
            </div>
          ))}
        </Content>
      </Main>
    </Container>
  );
};

const propTypes = {};

MemberList.propTypes = propTypes;

export default connectData(MemberList);
