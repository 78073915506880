import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { dashboard_v2 } from 'theme';

const { colors } = dashboard_v2;

const StyledAlert = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 36px 12px 16px;
  min-width: 280px;
  color: ${({ setting }) => setting.font};
  border: solid 1px ${({ setting }) => setting.border};
  background-color: ${({ setting }) => setting.background};
  ${({ margin }) => margin && `margin: ${margin}`};
  border-radius: 8px;
  i {
    font-size: 18px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 500;
    i {
      font-size: 18px;
    }
  }
`;

const Description = styled.div`
  margin-left: 26px;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
`;

const getTypeSetting = (type) => {
  switch (type) {
    case 'information':
      return {
        font: colors.PRIMARY_600,
        background: colors.PRIMARY_100,
        border: colors.PRIMARY_300,
        icon: <i className="ri-error-warning-line" />,
      };
    case 'error':
      return {
        font: colors.SYSTEM_ERROR_700,
        background: colors.SYSTEM_ERROR_100,
        border: colors.SYSTEM_ERROR_400,
        icon: <i className="ri-alert-line" />,
      };
    case 'warning':
      return {
        font: colors.SYSTEM_WARNING_900,
        background: colors.SYSTEM_WARNING_100,
        border: colors.SYSTEM_WARNING_500,
        icon: <i className="ri-error-warning-line" />,
      };
    case 'success':
      return {
        font: colors.SYSTEM_SUCCESS_700,
        background: colors.SYSTEM_SUCCESS_100,
        border: colors.SYSTEM_SUCCESS_400,
        icon: <i className="ri-checkbox-circle-line" />,
      };
    case 'general':
    default:
      return {
        font: colors.SHADES_600,
        background: colors.SHADES_100,
        border: colors.SHADES_400,
        icon: <i className="ri-fire-line" />,
      };
  }
};

const Alert = ({ type, title, description, margin, onClose, titleChildren }) => {
  const setting = getTypeSetting(type);
  return (
    <StyledAlert setting={setting} margin={margin}>
      <Title>
        {setting.icon}
        <span>{title}</span>
        {titleChildren}
      </Title>
      {description && <Description>{description}</Description>}
      {onClose && (
        <CloseButton onClick={onClose}>
          <i className="ri-close-line" />
        </CloseButton>
      )}
    </StyledAlert>
  );
};

const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string || PropTypes.node,
};

Alert.propTypes = propTypes;

export default memo(Alert);
