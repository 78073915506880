import IconCell, { LoadingCell as StatusLoading } from 'components/Table/Columns/Status/StatusCell';
import { getName } from 'components/Table/Columns/Status/status';
import { getTitle, filterOptions as progressFilterOptions } from 'components/Table/Columns/Progress/progress';
import {
  RenderCheckboxHeader,
  RenderCheckboxCell,
  RenderLoadingCell,
} from 'components/Table/Columns/IndeterminateCheckbox';
import TagCell from 'components/Table/Columns/Tag/TagCell';
import NoteReminderCell, {
  Loading as NoteReminderLoading,
} from 'components/Table/Columns/NoteReminder/NoteReminderCell';
import MessageCell, { Loading as MessageLoading } from 'components/Table/Columns/Message/MessageCell';
import ProgressCell, { Loading as ProgressLoading } from 'components/Table/Columns/Progress/ProgressCell';
import { t } from 'i18n/config';

const columns = [
  {
    id: 'selection',
    Header: RenderCheckboxHeader,
    Cell: RenderCheckboxCell,
    Loading: RenderLoadingCell,
    maxWidth: 50,
  },
  {
    Header: t('appointmentTime'),
    accessor: 'appointmentTime',
    maxWidth: 100,
  },
  {
    Header: t('lightSignal'),
    accessor: 'status',
    filterable: true,
    Cell: IconCell,
    toDisplayString: getName,
    Loading: StatusLoading,
    maxWidth: 100,
  },
  {
    Header: t('patientName'),
    accessor: 'patient',
    maxWidth: 120,
  },
  {
    Header: t('customTags'),
    accessor: 'customTags',
    Cell: TagCell,
    minWidth: 100,
  },
  {
    Header: t('mobile'),
    accessor: 'phone',
    maxWidth: 120,
  },
  {
    Header: t('seeingDoctor'),
    accessor: 'doctor',
    filterable: true,
    maxWidth: 120,
  },
  {
    Header: t('appointmentNotesPatientReminder'),
    accessor: 'noteReminder',
    Cell: NoteReminderCell,
    Loading: NoteReminderLoading,
    maxWidth: 160,
  },
  {
    Header: t('sendMessageDirectly'),
    accessor: 'message',
    Cell: MessageCell,
    Loading: MessageLoading,
    minWidth: 140,
    maxWidth: 160,
  },
  {
    Header: t('processingStatus'),
    accessor: 'progress',
    filterable: true,
    Cell: ProgressCell,
    filter: 'progressFilter',
    filterOptions: progressFilterOptions,
    toDisplayString: getTitle,
    Loading: ProgressLoading,
    minWidth: 100,
    maxWidth: 100,
  },
];

export default () => [
  {
    Header: 'Name',
    columns,
  },
];

export const tableStyles = {
  rowHeight: '175px',
};
