import { getToken, MARKETING_URL, ENV_STAGE } from 'helpers';
import { VendorCategory } from 'utils/vendors';
const baseURL = MARKETING_URL + '/v2/clients';

export async function getLineAction(payload) {
  const { clientId, vendor } = payload;
  const category = VendorCategory[vendor.toLowerCase()];

  try {
    const urlEndpoint =
      category === 'western'
        ? `${baseURL}/${clientId}/flexOnlyLineActions?service=aomori`
        : `${baseURL}/${clientId}/flexOnlyLineActions`;
    const response = await fetch(urlEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'AP-stage': ENV_STAGE,
        Authorization: `Bearer ${getToken()}`,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return response.ok ? { success: true, data } : { success: false, error: 'Request failed' };
  } catch (error) {
    console.error('patch setting failed:', error);
    return { success: false, error: 'Request failed' };
  }
}

export async function upsertLineAction(payload) {
  const { clientId, alias, data, vendor } = payload;
  const category = VendorCategory[vendor.toLowerCase()];

  try {
    const urlEndpoint =
      category === 'western'
        ? `${baseURL}/${clientId}/flexOnlyLineActions/alias/${alias}?service=aomori`
        : `${baseURL}/${clientId}/flexOnlyLineActions/alias/${alias}`;
    const response = await fetch(urlEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'AP-stage': ENV_STAGE,
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorResponse.message || 'Request failed'}`);
    }

    const responseData = await response.json();
    return { success: true, responseData };
  } catch (error) {
    console.error('patch setting failed:', error);
    return { success: false, error: error.message || 'Request failed' };
  }
}
