import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '70%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function TextFieldWithFlexVar({
  name,
  value = '',
  onChange,
  inputType = 'text',
  title,
  onValidColorChange,
  onTextEmptyStatus,
  onValidRatio,
}) {
  const classes = useStyles();

  const valueformater = (targetvalue) => {
    if (inputType === 'number') {
      return parseInt(targetvalue, 10);
    }
    return targetvalue;
  };
  const checkColorValid = (color) => {
    if (color === '{{myColor}}' || /^#([A-Fa-f0-9]{6})$/.test(color)) {
      return true;
    } else return false;
  };
  const checkIsValidAspectRatio = (value) => {
    const ratioPattern = /^[0-9]*\.?[0-9]+:[0-9]*\.?[0-9]+$/;
    return ratioPattern.test(value);
  };
  const handleChange = (e) => {
    if (e.target.name === 'color') {
      const newValue = e.target.value;
      onValidColorChange(checkColorValid(newValue));
    } else if (e.target.name === 'text' && onTextEmptyStatus) {
      const newValue = e.target.value;
      onTextEmptyStatus(!newValue);
    } else if (e.target.name === 'aspectRatio' && onValidRatio) {
      const newValue = e.target.value;
      onValidRatio(checkIsValidAspectRatio(newValue));
      console.log('textFiledValid:', checkIsValidAspectRatio(newValue));
    }
    console.log('onValidRatio:', onValidRatio);
    onChange({
      [e.target.name]: valueformater(e.target.value) || undefined,
    });
  };

  if (name === ('color' || 'backgroundColor')) {
    return (
      <FormControl className={classes.formControl}>
        <TextField
          error={!checkColorValid(value) || !value}
          autoFocus
          multiline
          type={inputType}
          label={title}
          name={name}
          value={value}
          onChange={handleChange}
        />
      </FormControl>
    );
  } else if (name === 'aspectRatio') {
    return (
      <FormControl className={classes.formControl}>
        <TextField
          error={!checkIsValidAspectRatio(value) || !value}
          autoFocus
          multiline
          type={inputType}
          label={title}
          name={name}
          value={value}
          onChange={handleChange}
        />
      </FormControl>
    );
  } else {
    return (
      <FormControl className={classes.formControl}>
        <TextField
          error={!value}
          autoFocus
          multiline
          type={inputType}
          label={title}
          name={name}
          value={value}
          onChange={handleChange}
        />
      </FormControl>
    );
  }
}

TextFieldWithFlexVar.propTypes = {
  name: PropTypes.string,
  varText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  inputType: PropTypes.string,
};

export default TextFieldWithFlexVar;
