import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import TextEditor from './ComponentEditors/TextEditor';
import Swapper from './ComponentEditors/Swapper';
import MainKeysEditor from './ComponentEditors/MainKeysEditor';
import ImageEditor from './ComponentEditors/ImageEditor';
import ButtonEditor from './ComponentEditors/ButtonEditor';

export const mainKeys = ['header', 'hero', 'body', 'footer'];
const Container = styled.div``;

function FlexElementEditor({
  path,
  parentPath,
  content,
  index,
  onChange,
  onRemove,
  onChangeStyles,
  mainStyles,
  onRemoveMainKey,
  onRemoveBubble,
  onValidColorChange,
  onTextEmptyStatus,
  onValidRatio,
}) {
  const handleChangeStyles = (styles) => {
    onChangeStyles({
      path,
      styles,
    });
  };
  const onAdd = (newContent) => () => {
    onChange({
      path,
      content: {
        ...content,
        contents: content.contents.concat([newContent]),
      },
      parentPath,
      index,
    });
  };

  const handleRemove = () => {
    onRemove({
      path,
      content,
      parentPath,
      index,
    });
  };
  if (parentPath === '' && path === 'carousel') {
    return null;
  }
  // bubble 下新增 mainKey
  if (parentPath === '' && path === '' && content.type === 'bubble') {
    return null;
  }
  if (parentPath === 'carousel') {
    return (
      <Container>
        <Swapper onRemove={onRemoveBubble} />
      </Container>
    );
  }
  // bubble MainKey
  if (_.includes(mainKeys, path)) {
    return (
      <Container>
        <MainKeysEditor
          onChange={onChange}
          parentPath={parentPath}
          index={index}
          mainStyles={mainStyles[path]}
          onAdd={onAdd}
          onChangeStyles={handleChangeStyles}
          onRemove={onRemoveMainKey}
          content={content}
          path={path}
          onValidColorChange={onValidColorChange}
          onValidRatio={onValidRatio}
        />
      </Container>
    );
  }
  // carousel MainKey
  if (_.includes(mainKeys, path.split('.').length < 3 && path.split('.')[1])) {
    console.log('carousel mainkey');
    return (
      <Container>
        <MainKeysEditor
          onChange={onChange}
          parentPath={parentPath}
          index={index}
          onAdd={onAdd}
          onChangeStyles={handleChangeStyles}
          onRemove={onRemoveMainKey}
          content={content}
          path={path}
          onValidColorChange={onValidColorChange}
          onValidRatio={onValidRatio}
        />
      </Container>
    );
  }
  if (content.type === 'image') {
    return (
      <Container>
        <ImageEditor
          onChange={onChange}
          path={path}
          parentPath={parentPath}
          index={index}
          content={content}
          onRemove={handleRemove}
          onValidRatio={onValidRatio}
        />
      </Container>
    );
  }
  if (content.type === 'text' || content.type === 'span') {
    return (
      <TextEditor
        onChange={onChange}
        path={path}
        parentPath={parentPath}
        index={index}
        content={content}
        onRemove={handleRemove}
        onValidColorChange={onValidColorChange}
        onTextEmptyStatus={onTextEmptyStatus}
      />
    );
  }
  if (content.type === 'button') {
    return (
      <ButtonEditor
        onChange={onChange}
        path={path}
        parentPath={parentPath}
        index={index}
        content={content}
        onRemove={handleRemove}
        onValidColorChange={onValidColorChange}
        onTextEmptyStatus={onTextEmptyStatus}
      ></ButtonEditor>
    );
  }
  return null;
}

FlexElementEditor.propTypes = {
  path: PropTypes.string,
  content: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
    contents: PropTypes.array,
  }),
  parentPath: PropTypes.string,
  index: PropTypes.number,
  onChange: PropTypes.func,
  onSwap: PropTypes.func,
  onRemove: PropTypes.func,
  onChangeStyles: PropTypes.func,
  onAddMainKey: PropTypes.func,
  onRemoveMainKey: PropTypes.func,
  mainStyles: PropTypes.shape({}),
};

export default FlexElementEditor;
