export default {
  HEADER: 'header',
  HERO: 'hero',
  FOOTER: 'footer',
};

export const ordering = {
  size: 0,
  type: 1,
  styles: 2,
  header: 3,
  hero: 4,
  body: 5,
  footer: 6,
};

export const orderJsonEntries = (a, b) => ordering[a[0]] - ordering[b[0]];
