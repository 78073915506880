import React, { useMemo, useState, useEffect, useCallback, memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import {
  isNationalIdentificationNumberValid,
  isResidentCertificateNumberValid,
  isNewResidentCertificateNumberValid,
} from 'taiwan-id-validator';
import phoneValidation from 'phone';

import { createMember, updateMember } from 'lib/memberService';
import { getTags } from 'reducers/members';
import { fetchMember } from 'lib/memberService';
import connectData from 'components/HOCs/connectData';
import useAlert from 'hooks/useAlert';
import useMemberIdTypes from 'feature/MemberCenter/hooks/useMemberIdTypes';

import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Block from 'components/Dashboard_v2/Block';
import FormControl from 'components/Dashboard_v2/FormControl';
import Input from 'components/Dashboard_v2/Input';
import DatePicker from 'components/Dashboard_v2/DatePicker/Input';
import Select from 'components/Dashboard_v2/Select';
import Radio from 'components/Dashboard_v2/Radio';
import Text from 'components/Dashboard_v2/Text';
import InfoPopover from 'components/Dashboard_v2/InfoPopover';
import TagSelectorInput from 'components/Dashboard_v2/TagSelectorInput';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const DATE_FORMAT = 'YYYY/MM/DD';

const InputField = {
  NAME: 'name',
  BIRTH: 'birthday',
  ID_NUM: 'idNum',
  PHONE: 'phone',
  GENDER: 'gender',
};

const Container = styled.div`
  position: relative;
  padding-bottom: 250px;
`;

const Header = styled.div`
  padding: 30px 30px 24px;
  margin: 0 -30px;
  background-color: #fff;
  border-bottom: 1px solid ${colors.SHADES_200};
  display: flex;
  ${({ onProfileEditSave }) =>
    onProfileEditSave &&
    `
      padding: 0;
      margin: 0;
      border: none;
      margin-bottom: 24px;
  `};
`;

const ProfileEditWrapper = styled.div``;

const ProfileEditHeader = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  border: none;
  margin-bottom: 24px;
`;

const FormActions = styled.div`
  margin-left: auto;
  display: flex;
  gap: 0 8px;
`;

const Main = styled.div`
  margin: 30px 0;
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;

  i {
    font-size: 14px;
  }
`;

const TagDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  i {
    margin: 0 4px 0 8px;
    font-size: 16px;
    color: ${colors.SHADES_400};
  }
  span {
    color: ${colors.SHADES_400};
  }
`;

const InfoTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-right: 8px;
  }
`;

const MemberIdInputsWrapper = styled.div`
  margin-bottom: 18px;
  > div {
    display: flex;
    align-items: baseline;
    margin-bottom: 18px;
  }
  > span:last-child {
    cursor: pointer;
  }
`;

const DeleteIcon = styled.i`
  margin-left: 12px;
  font-size: 18px;
  color: ${colors.SHADES_400};
  cursor: pointer;
`;

const RequiredHintWrapper = styled.span`
  color: ${colors.SHADES_400};
`;

const RequiredHint = styled.span`
  color: ${colors.SHADES_400};

  :before {
    content: '*';
    color: ${colors.SYSTEM_ERROR_500};
  }
`;

const InfoTitle = ({ title, popoverContent }) => {
  return (
    <InfoTitleWrapper>
      <InfoPopover popoverContent={popoverContent} />
      {title}
    </InfoTitleWrapper>
  );
};

const TagDescription = () => {
  return (
    <TagDescriptionWrapper>
      {t('addTagsForMember')}
      <InfoPopover popoverContent={t('tagCustomerDescription')} />
      <span>{t('optional')}</span>
    </TagDescriptionWrapper>
  );
};

const initialMemberState = {
  name: '',
  birthday: '',
  twID: '',
  pdID: '',
  hisID: '',
  memberID: '',
  phone: '',
  gender: '',
};

const MODE_NAME_MAP = {
  create: {
    title: t('add'),
    description: t('addMemberDataRequired'),
    saveButton: t('saveAndAdd'),
  },
  edit: {
    title: t('edit'),
    description: t('editMemberDataRequired'),
    saveButton: t('updateAndClose'),
  },
};

const getMemberIds = ({ member, idTypes, memberIdInputs }) => {
  const types = idTypes.map((type) => type.value);
  const memberIds = memberIdInputs.reduce((obj, input) => {
    obj[input.type.value] = input.value;
    return obj;
  }, {});

  if (!member.idType) {
    for (let i = 0; i < types.length; i++) {
      const patientTypeData = memberIdInputs.find((input) => input.type.value === types[i]);
      if (patientTypeData) {
        memberIds.idType = patientTypeData.type.value;
        memberIds.idNum = patientTypeData.value;
        break;
      }
    }
  }

  return memberIds;
};

const convertMemberIdsToInputs = ({ idTypes, memberData }) => {
  const defaultData = [{ type: idTypes[0], value: '' }];
  if (!memberData) return defaultData;

  const types = Object.values(idTypes).map((type) => type.value);
  const idInputData = [];

  types.forEach((type, index) => {
    if (memberData[type]) {
      idInputData.push({ type: idTypes[index], value: memberData[type] });
    }
  });

  return idInputData.length ? idInputData : defaultData;
};

const MemberDataFields = ({
  mode,
  member,
  errInput,
  handleChange,
  idTypes,
  handleRadioChange,
  handleBirthdayChange,
  idTypeOptions,
  hideIdentifyColumns,
  handleIdInputTypeChange,
  handleIdInputValueChange,
  handleIdInputValueRemove,
  handleAddMemberIdInput,
  memberIdInputs,
}) => {
  return (
    <>
      <FormControl>
        <Input
          name={InputField.NAME}
          label={t('customName')}
          width={350}
          helperText={errInput[InputField.NAME]}
          value={member.name}
          onInputChange={handleChange}
          error={!!errInput[InputField.NAME]}
          placeholder={t('exampleName')}
          required
        />
      </FormControl>
      {mode === 'edit' && (
        <React.Fragment>
          <FormControl>
            <Input
              label={<InfoTitle title="LINE" popoverContent={t('lineMemberName')} />}
              value={member.lineName}
              width={350}
              disabled
            />
          </FormControl>
          <FormControl>
            <Input
              label={<InfoTitle title="HIS" popoverContent={t('hisMemberName')} />}
              width={350}
              value={member.hisName}
              disabled
            />
          </FormControl>
        </React.Fragment>
      )}
      <FormControl>
        <Radio
          label={t('gender')}
          name={InputField.GENDER}
          value={member.gender}
          onChange={handleRadioChange}
          items={[
            { label: t('biologicalMale'), value: 'M' },
            { label: t('biologicalFemale'), value: 'F' },
          ]}
          error={!!errInput[InputField.GENDER]}
          helperText={errInput[InputField.GENDER]}
          required
        />
      </FormControl>
      <FormControl>
        <DatePicker
          name={InputField.BIRTH}
          value={member.birthday}
          label={t('birthday')}
          width={350}
          helperText={errInput[InputField.BIRTH]}
          error={!!errInput[InputField.BIRTH]}
          onChange={handleBirthdayChange}
          placeholder="1983/01/01"
          yearRange={{ startYear: 1900, endYear: new Date().getFullYear() }}
          maxDate={new Date()}
          required
        />
      </FormControl>
      {!hideIdentifyColumns && (
        <MemberIdInputsWrapper>
          <Text font="Heading/Small/Regular" color="SHADES_400" marginBottom={8}>
            {t('identifier')}
            <Text font="Heading/Small/Regular" color="SYSTEM_ERROR_500">
              *
            </Text>
          </Text>
          {memberIdInputs.map((idInput, index) => (
            <div key={idInput.type.id}>
              <Select
                options={idTypeOptions}
                selectedOption={memberIdInputs[index].type}
                setSelectedOption={(option) => handleIdInputTypeChange(index, option)}
                disabled={member.idType === memberIdInputs[index].type.value}
                width={170}
              />
              <Input
                required
                name={memberIdInputs[index].type.value}
                value={memberIdInputs[index].value}
                width={172}
                marginLeft={8}
                onInputChange={(e) => handleIdInputValueChange(index, e)}
                helperText={errInput[memberIdInputs[index].type.value]}
                error={!!errInput[memberIdInputs[index].type.value]}
                disabled={member.idType === memberIdInputs[index].type.value}
              />
              {member.idType !== memberIdInputs[index].type.value && (
                <DeleteIcon className="ri-delete-bin-line" onClick={() => handleIdInputValueRemove(index)} />
              )}
            </div>
          ))}
          {memberIdInputs.length < idTypes.length && (
            <Text onClick={handleAddMemberIdInput} font="Body/14px_Medium" color="PRIMARY_500" marginLeft={8} as="div">
              <i className="ri-add-line" />
              <Text font="Body/14px_Medium" color="PRIMARY_500" marginLeft={8}>
                新增其他識別欄位
              </Text>
            </Text>
          )}
        </MemberIdInputsWrapper>
      )}
      <FormControl>
        <Input
          name={InputField.PHONE}
          label={t('mobileNumber')}
          width={350}
          value={member.phone}
          helperText={errInput[InputField.PHONE]}
          onInputChange={handleChange}
          error={!!errInput[InputField.PHONE]}
          placeholder="0988123456"
          required
        />
      </FormControl>
    </>
  );
};

const MemberForm = ({ mode, match, id, isProfileEdit, onClose }) => {
  const memberId = match?.params?.id || id;
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients);
  const { idTypes } = useMemberIdTypes();
  const { id: clientId } = clients.byId[clients.selectedIds[0]];
  const { renderAlert, setAlert } = useAlert();
  const [member, setMember] = useState(null);
  const [memberIdInputs, setMemberIdInputs] = useState();
  const [shouldHideIdentifyColumns, setShouldHideIdentifyColumns] = useState(false);
  const [skipTwIdCheck, setSkipTwIdCheck] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [errInput, setErrInput] = useState({});
  const [redirectToList, setRedirectToList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const idTypeOptions = useMemo(() => {
    if (!memberIdInputs || !idTypes) return [];

    const selectedTypes = memberIdInputs.map((input) => input.type.value);
    return idTypes.map((type) => ({
      ...type,
      disabled: selectedTypes.includes(type.value),
    }));
  }, [memberIdInputs, idTypes]);

  const checkValidation = useCallback(() => {
    const errInput = {};
    if (member.name === '') {
      errInput[InputField.NAME] = t('confirmNameFormat');
    }
    if (member.gender === '') {
      errInput[InputField.GENDER] = t('selectGender');
    }
    if (!moment(member.birthday, DATE_FORMAT, true).isValid()) {
      errInput[InputField.BIRTH] = t('confirmDateFormat');
    }
    // phone validation
    const phoneNumber = member.phone.startsWith('09') ? `+886${member.phone}` : member.phone;
    if (!phoneNumber.match(/^\+?\d+$/) || !phoneValidation(phoneNumber).isValid) {
      errInput[InputField.PHONE] = t('confirmMobileFormat');
    }

    // identifies checker
    if (!shouldHideIdentifyColumns) {
      memberIdInputs.forEach((idInput) => {
        const idType = idInput.type.value;
        const value = idInput.value;
        if (!value) {
          errInput[idType] = t('fillField', { name: idInput.type.name });
        } else if (
          !skipTwIdCheck &&
          idType === 'twID' &&
          !(
            isNationalIdentificationNumberValid(value) ||
            isResidentCertificateNumberValid(value) ||
            isNewResidentCertificateNumberValid(value)
          )
        ) {
          errInput[idType] = t('confirmIdFormat');
        }
      });
    }

    setErrInput(errInput);
    return errInput;
  }, [member, memberIdInputs, skipTwIdCheck, shouldHideIdentifyColumns]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMember((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBirthdayChange = (date) => {
    setMember((prevState) => ({
      ...prevState,
      birthday: date,
    }));
  };

  const handleRadioChange = (value) => {
    setMember((prevState) => ({
      ...prevState,
      gender: value,
    }));
  };

  const handleAddMemberIdInput = () => {
    const selectedTypes = memberIdInputs.map((input) => input.type.value);
    const restIdTypeOptions = idTypes.filter((type) => !selectedTypes.includes(type.value));
    setMemberIdInputs((prev) => [...prev, { type: restIdTypeOptions[0], value: '' }]);
  };

  const handleIdInputTypeChange = (index, option) => {
    const cloneIdInputs = [...memberIdInputs];
    cloneIdInputs[index].type = option;
    setMemberIdInputs(() => cloneIdInputs);
  };

  const handleIdInputValueChange = (index, e) => {
    const cloneIdInputs = [...memberIdInputs];
    cloneIdInputs[index].value = e.target.value;
    setMemberIdInputs(() => cloneIdInputs);
  };

  const handleIdInputValueRemove = (index) => {
    const cloneIdInputs = [...memberIdInputs];
    const idType = cloneIdInputs[index].type.value;
    cloneIdInputs.splice(index, 1);
    setMember((prev) => ({ ...prev, [idType]: '' }));
    setMemberIdInputs(() => cloneIdInputs);
  };

  const handleSubmit = async () => {
    if (isLoading) return;

    const errMsg = checkValidation();
    if (Object.keys(errMsg).length == 0) {
      setIsLoading(true);
      const payload = {
        clientId,
        member: {
          ...member,
          ...getMemberIds({ member, idTypes, memberIdInputs }),
          tags: selectedTags,
        },
      };

      try {
        if (mode === 'create') {
          Object.assign(payload.member, { createdBy: 'created_by_dashboard' });
          await createMember(payload);
        }
        if (mode === 'edit') {
          Object.assign(payload, { memberId });
          await updateMember(payload);
        }
        setAlert({ type: 'success', title: t('memberActionSuccess', { title: MODE_NAME_MAP[mode].title }) });
        if (isProfileEdit) {
          onClose({ memberData: payload.member });
        } else {
          setRedirectToList(true);
        }
      } catch (e) {
        const errRes = await e;
        const { error } = errRes;
        setAlert({ type: 'error', title: error || t('memberActionFailed', { title: MODE_NAME_MAP[mode].title }) });
        console.error(errRes);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(errInput).length !== 0) {
      checkValidation();
    }
  }, [member, memberIdInputs]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!idTypes) return;

    dispatch(getTags({ clientId }));
    if (mode === 'create') {
      setMember(initialMemberState);
      setMemberIdInputs(convertMemberIdsToInputs({ idTypes }));
    }
    if (mode === 'edit') {
      const getMemberData = async () => {
        const memberData = await fetchMember({ clientId, memberId });
        setMemberIdInputs(convertMemberIdsToInputs({ idTypes, memberData }));
        setMember(memberData);
        if (memberData.idType === 'lineID') {
          setShouldHideIdentifyColumns(true);
        }
        if (memberData.twID) {
          setSkipTwIdCheck(true);
        }
        setSelectedTags(memberData.tags);
      };
      getMemberData();
    }
  }, [mode, dispatch, clientId, memberId, idTypes]);

  if (isProfileEdit) {
    return (
      member && (
        <ProfileEditWrapper>
          <ProfileEditHeader>
            <Text font="Heading/H6/Medium">{t('memberInformation')}</Text>
            <FormActions>
              <Button color="secondary" variant="ghost" onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button color="primary" variant="filled" onClick={handleSubmit}>
                {isLoading ? <CircularProgress style={{ color: '#fff' }} size={20} /> : MODE_NAME_MAP[mode].saveButton}
              </Button>
            </FormActions>
          </ProfileEditHeader>
          <MemberDataFields
            mode={mode}
            member={member}
            errInput={errInput}
            handleChange={handleChange}
            idTypes={idTypes}
            handleRadioChange={handleRadioChange}
            handleBirthdayChange={handleBirthdayChange}
            idTypeOptions={idTypeOptions}
            hideIdentifyColumns={shouldHideIdentifyColumns}
            handleIdInputTypeChange={handleIdInputTypeChange}
            handleIdInputValueChange={handleIdInputValueChange}
            handleIdInputValueRemove={handleIdInputValueRemove}
            handleAddMemberIdInput={handleAddMemberIdInput}
            memberIdInputs={memberIdInputs}
          />
        </ProfileEditWrapper>
      )
    );
  }

  return redirectToList ? (
    <Redirect push to="/members" />
  ) : (
    <Container isProfileEdit>
      {renderAlert()}
      <Header>
        <Link to="/members">
          <IconButton color="secondary" variant="outline">
            <i className="ri-arrow-left-line" />
            {t('goBack')}
          </IconButton>
        </Link>
        <FormActions>
          <Link to="/members">
            <Button color="secondary" variant="ghost">
              {t('cancel')}
            </Button>
          </Link>
          <Button color="primary" variant="filled" onClick={handleSubmit}>
            {isLoading ? <CircularProgress style={{ color: '#fff' }} size={20} /> : MODE_NAME_MAP[mode].saveButton}
          </Button>
        </FormActions>
      </Header>
      {member && (
        <Main>
          <Block
            title={MODE_NAME_MAP[mode].title}
            description={
              <span>
                {MODE_NAME_MAP[mode].description}{' '}
                <RequiredHintWrapper>
                  (<RequiredHint>{t('common.required')}</RequiredHint>)
                </RequiredHintWrapper>
              </span>
            }
            isProfileEdit
          >
            <MemberDataFields
              mode={mode}
              member={member}
              errInput={errInput}
              handleChange={handleChange}
              idTypes={idTypes}
              handleRadioChange={handleRadioChange}
              handleBirthdayChange={handleBirthdayChange}
              idTypeOptions={idTypeOptions}
              handleIdInputTypeChange={handleIdInputTypeChange}
              handleIdInputValueChange={handleIdInputValueChange}
              handleIdInputValueRemove={handleIdInputValueRemove}
              handleAddMemberIdInput={handleAddMemberIdInput}
              memberIdInputs={memberIdInputs}
            />
          </Block>
          <Block title={t('tags')} description={<TagDescription />}>
            <FormControl margin={'none'}>
              <TagSelectorInput selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
            </FormControl>
          </Block>
        </Main>
      )}
    </Container>
  );
};

const propTypes = {
  mode: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

MemberForm.propTypes = propTypes;

export const ProfileMemberEditForm = memo(MemberForm);

export default connectData(MemberForm);
