import Alert from 'components/Dashboard_v2/Alert';
import Text from 'components/Dashboard_v2/Text';
import UploadArea, { UPLOAD_STATUS } from 'components/Dashboard_v2/UploadArea';
import FilterableTable from 'components/Table/FilterableTable';
import useMemberIdTypes from 'feature/MemberCenter/hooks/useMemberIdTypes';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import readXlsxFile from 'read-excel-file';
import styled from 'styled-components';
import {
  isNationalIdentificationNumberValid,
  isNewResidentCertificateNumberValid,
  isResidentCertificateNumberValid,
} from 'taiwan-id-validator';
import { dashboard_v2 } from 'theme';
import formatBytes from 'utils/formatBytes';
import tableHeaderColumns, { tableStyles } from './columns';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const DATE_FORMAT = 'YYYY/MM/DD';

const FileInfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${colors.SHADES_300};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  > div {
    display: flex;
    align-items: center;
    i {
      font-size: 28px;
      color: ${colors.SHADES_400};
    }
    span {
      margin-left: 12px;
      color: ${colors.SHADES_500};
      font-size: 14px;
      &:last-child {
        margin-left: 8px;
        padding-top: 2px;
        font-size: 12px;
        color: ${colors.SHADES_400};
      }
    }
  }
  > i {
    font-size: 28px;
    color: ${colors.SHADES_400};
    cursor: pointer;
    &:hover {
      color: ${colors.SHADES_500};
      transition: 0.3s;
    }
  }
`;

const AlertContainer = styled.div`
  margin-bottom: 24px;
`;

const getGender = (value) => {
  switch (value) {
    case t('male'):
      return 'M';
    case t('female'):
      return 'F';
    default:
      return value;
  }
};

const getMemberIds = (idTypes, data) => {
  const mapData = Object.keys(data).map((key) => ({ key, value: data[key] }));
  const types = idTypes.map((type) => type.value);
  const memberIds = { ...data };

  for (let i = 0; i < types.length; i++) {
    const patientTypeData = mapData.find((d) => d.key === types[i] && d.value);
    if (patientTypeData) {
      memberIds.idType = patientTypeData.key;
      memberIds.idNum = patientTypeData.value;
      break;
    }
  }
  return memberIds;
};

const formatBirthday = (data = '') => {
  const separate = data?.includes('/') ? '/' : data?.includes('-') ? '-' : null;
  if (!separate) return;

  const splitBirthday = data.split(separate);
  if (splitBirthday[0].length < 4) {
    splitBirthday[0] = `${parseInt(splitBirthday[0], 10) + 1911}`;
  }

  return splitBirthday.join('/');
};

const getValidateData = (index, data) => {
  try {
    const { twID, hisID, memberID, pdID, name, phone, birthday, gender } = data;
    const invalidColumns = [];

    // ids validation
    if (Object.values({ twID, hisID, memberID, pdID }).every((value) => !value)) {
      Object.assign(data, {
        twID: t('requiredOne'),
        hisID: t('requiredOne'),
        memberID: t('requiredOne'),
        pdID: t('requiredOne'),
      });
      invalidColumns.push('twID', 'hisID', 'memberID', 'pdID');
    } else if (
      twID &&
      !(
        isNationalIdentificationNumberValid(twID) ||
        isResidentCertificateNumberValid(twID) ||
        isNewResidentCertificateNumberValid(twID)
      )
    ) {
      invalidColumns.push('twID');
    }

    // name validation
    if (!name) {
      data.name = t('requiredField');
      invalidColumns.push('name');
    }

    // phone validation
    if (!phone) {
      data.phone = t('requiredField');
      invalidColumns.push('phone');
    } else if (!`${phone}`.match(/^(09)[0-9]{8}$/)) {
      invalidColumns.push('phone');
    }

    // birthday validation
    if (!birthday) {
      data.birthday = t('requiredField');
      invalidColumns.push('birthday');
    } else if (!moment(formatBirthday(birthday), DATE_FORMAT, true).isValid()) {
      invalidColumns.push('birthday');
    }

    // gender validation
    if (!gender) {
      data.gender = t('requiredField');
      invalidColumns.push('gender');
    } else if (![t('male'), t('female'), 'M', 'F'].includes(gender)) {
      invalidColumns.push('gender');
    }

    return invalidColumns.length ? { ...data, index, invalidColumns } : data;
  } catch (e) {
    console.error(`[FileUploader Error]: ${e}`);
    return {};
  }
};

const FileUploader = ({ setUploadedData, alert }) => {
  const inputRef = useRef();
  const { idTypes } = useMemberIdTypes();
  const [status, setStatus] = useState(UPLOAD_STATUS.INITIAL);
  const [uploadInfo, setUploadInfo] = useState({ name: null, size: null, alert: null });
  const [invalidData, setInvalidData] = useState(null);

  const handleFileSelect = useCallback(
    async (e) => {
      const file = e.target.files[0];
      if (!file) return;

      setUploadInfo(null);
      setStatus(UPLOAD_STATUS.UPLOADING);

      const rows = await readXlsxFile(file);

      rows.splice(0, 1); /* rows[0] = columns head, should be removed. */

      const mapRows = rows.map((row, index) => {
        const stringRow = row.map((value) => value?.toString() || value);
        const [twID, hisID, memberID, pdID, name, phone, birthday, gender] = stringRow;
        const data = { twID, hisID, memberID, pdID, name, phone, birthday, gender };

        // format data
        Object.assign(data, {
          gender: getGender(gender),
          birthday: formatBirthday(birthday),
          createdBy: 'batch_created_by_dashboard',
          ...getMemberIds(idTypes, { twID, hisID, memberID, pdID }),
        });

        return getValidateData(index + 3, data); // +3 for display index (include sheet title & head row)
      });

      const { validRows, invalidRows } = mapRows.reduce(
        (group, row) => {
          row.invalidColumns ? group.invalidRows.push(row) : group.validRows.push(row);
          return group;
        },
        { validRows: [], invalidRows: [] }
      );

      const alert =
        invalidRows.length === 0
          ? { type: 'success', title: t('validCount', { total: mapRows.length }) }
          : {
              type: 'warning',
              title: t('effectiveValidCount', { count: mapRows.length - invalidRows.length, total: mapRows.length }),
            };

      setUploadedData(validRows);
      setInvalidData(invalidRows);
      setUploadInfo({ name: file.name, size: formatBytes(file.size), alert });
      setStatus(UPLOAD_STATUS.DONE);
    },
    [setUploadedData, setInvalidData, idTypes]
  );

  const handleFileClear = useCallback(() => {
    inputRef.current.value = '';
    setUploadedData(null);
    setInvalidData(null);
    setUploadInfo(null);
    setStatus(UPLOAD_STATUS.INITIAL);
  }, [setUploadedData, setInvalidData]);

  useEffect(() => {
    if (alert) {
      setUploadInfo({ ...uploadInfo, alert: { type: 'error', title: alert } });
    }
  }, [alert]);

  return (
    <div>
      {uploadInfo?.alert && (
        <AlertContainer>
          <Alert
            type={uploadInfo.alert.type}
            title={uploadInfo.alert.title}
            onClose={() => setUploadInfo({ ...uploadInfo, alert: null })}
          />
        </AlertContainer>
      )}

      <UploadArea
        innerRef={inputRef}
        status={status}
        hint={t('supportedFormat')}
        accept=".xlsx"
        onFileSelect={handleFileSelect}
      />

      {uploadInfo?.name && uploadInfo?.size && (
        <React.Fragment>
          <FileInfoBlock>
            <div>
              <i className="ri-file-text-line" />
              <span>{uploadInfo?.name}</span>
              <span>{uploadInfo?.size}</span>
            </div>
            <i className="ri-delete-bin-line" onClick={handleFileClear} />
          </FileInfoBlock>
          {invalidData && (
            <React.Fragment>
              <Text font="Heading/Medium/Regular" marginBottom={16}>
                {t('invalidData')}
              </Text>
              <FilterableTable
                tableStyles={tableStyles}
                tableHeaderColumns={tableHeaderColumns}
                data={invalidData}
                shouldRowsRemoved={true}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

FileUploader.propTypes = {
  setUploadedData: PropTypes.func,
  setInvalidData: PropTypes.func,
};

export default FileUploader;
