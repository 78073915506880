import { useState, useEffect, useRef, useCallback } from 'react';
import { getLatestMembersUploadProcess } from 'lib/memberService';
import { dashboard_v2 } from 'theme';
import styled from 'styled-components';
import { t } from 'i18n/config';
import { captureException } from '@sentry/react';

const Circle = ({ color, pct }) => {
  const r = 30;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={40}
      cy={40}
      fill="transparent"
      stroke={strokePct !== circ ? color : ''}
      strokeWidth={'5px'}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

const Pie = ({ percentage }) => {
  return (
    <svg width={80} height={80}>
      <g transform={`rotate(-90 ${'40 40'})`}>
        <Circle color={dashboard_v2.colors.SHADES_200} />
        <Circle color={dashboard_v2.colors.PRIMARY_300} pct={percentage} />
      </g>
    </svg>
  );
};

const CircularProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 40px;
  background-color: ${dashboard_v2.colors.SHADES_000};
  border-radius: 8px;
  h6 {
    font-size: ${dashboard_v2.fonts['EN/Heading/H6/Medium']};
    color: ${dashboard_v2.colors.SHADES_900};
  }
  .percentage {
    font-size: ${dashboard_v2.fonts['Body/16px_Medium']};
    color: ${dashboard_v2.colors.PRIMARY_500};
  }
`;

const CircleProgressBar = ({ start = 0, end, duration = 2, onProcessCallback }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    const startTime = performance.now();
    const totalDuration = duration * 500;

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / totalDuration, 1);

      const currentCount = start + (end - start) * progress;
      setCount(currentCount);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        setCount(end);
      }
    };

    requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animate);
  }, [start, end, duration]);

  useEffect(() => {
    if (count === 100) {
      onProcessCallback();
    }
  }, [count]);

  return (
    <CircularProgressContainer>
      <h6>{t('import.message.uploading')}</h6>
      <Pie percentage={count} color={'blue'} />
      <div className="percentage">
        {t('import.uploading')} - {Math.floor(count)}%
      </div>
    </CircularProgressContainer>
  );
};

const ProgressPie = ({ onProcessCallback, clientId }) => {
  const [progress, setProgress] = useState({ prev: 0, current: 0 });
  const requestRef = useRef(null);
  const startTimeRef = useRef(null);

  const handleProgressUpdate = useCallback(async () => {
    try {
      const data = await getLatestMembersUploadProcess(clientId);
      setProgress((prev) => ({
        prev: prev.current,
        current: data.percentage,
      }));

      if (data.percentage === 100 || data.error) {
        cancelAnimationFrame(requestRef.current);
        setTimeout(() => {
          onProcessCallback(data.error);
        }, 1000);
        return false;
      }
      return true;
    } catch (error) {
      onProcessCallback(error);
      cancelAnimationFrame(requestRef.current);
      captureException(`error for uploading process ${error}`);
      return false;
    }
  }, [clientId]);

  const updateProgress = useCallback(async (timestamp) => {
    if (!startTimeRef.current) {
      startTimeRef.current = timestamp || 0;
    }
    const elapsed = timestamp - startTimeRef.current;
    if (elapsed >= 5000) {
      const shouldContinue = await handleProgressUpdate();
      if (!shouldContinue) return;

      startTimeRef.current = timestamp;
    }
    requestRef.current = requestAnimationFrame(updateProgress);
  }, []);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(updateProgress);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [updateProgress]);

  return (
    <CircleProgressBar
      start={progress.prev}
      end={progress.current}
      duration={2}
      onProcessCallback={onProcessCallback}
    />
  );
};
export default ProgressPie;
