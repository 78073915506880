import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import Spinner from 'react-spinkit';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import connectData from 'components/HOCs/connectData';
import { json2xlsx } from '../../lib/xlsx';
import { isLogin, getDateFormat } from '../../helpers';
import {
  fetchReportCSV,
  fetchSurveyCSV,
  fetchFeedbackCSV,
  fetchReviewReportXlsx,
  doneRefreshPage,
  getMonthlyReportIframeUrl,
} from '../../reducers/customers';
import { fetchPnpAppointmentDeliveryList } from '../../reducers/line';
import { convertPnpKeyToTitle } from './jsonKeyToHeaderTitle';
import { t } from 'i18n/config';

const mapStateToProps = (state) => ({
  isRefresh: state.ui.isRefresh,
});

const mapDispatchToProps = {
  fetchReportCSV,
  fetchSurveyCSV,
  fetchFeedbackCSV,
  fetchReviewReportXlsx,
  doneRefreshPage,
  fetchPnpAppointmentDeliveryList,
};

@connectData
@connect(mapStateToProps, mapDispatchToProps)
export default class Report extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    clients: PropTypes.shape({
      byId: PropTypes.object,
      selectedIds: PropTypes.array,
    }),
    fetchReportCSV: PropTypes.func,
    fetchSurveyCSV: PropTypes.func,
    fetchFeedbackCSV: PropTypes.func,
    fetchReviewReportXlsx: PropTypes.func,
    isRefresh: PropTypes.bool,
    doneRefreshPage: PropTypes.func,
    fetchPnpAppointmentDeliveryList: PropTypes.func,
  };
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    const today = new Date();
    const y = today.getFullYear();
    const m = today.getMonth();
    const firstDay = new Date(y, m, 2);
    this.state = {
      dropdownOpen: false,
      iframeHeight: 0,
      iframeHtml: '',
      isloadingIframe: false,
      isShowReport: false,
      startDate: moment(firstDay.toISOString().slice(0, 10)),
      endDate: moment(new Date()),
      focusedInput: null,
      iframeSrc: null,
    };
  }
  componentDidMount() {
    if (!isLogin()) {
      this.props.history.push('/login');
    }
    this.onListenIframeChange();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isRefresh === false && prevProps.isRefresh !== this.props.isRefresh) {
      this.handleOpenReport();
    }
  }

  onListenIframeChange = () => {
    window.addEventListener('message', (e) => {
      if (typeof e.data === 'object' && typeof e.data.height === 'number') {
        this.setState({
          iframeHeight: e.data.height,
          iframeHtml: e.data.html,
          isloadingIframe: false,
        });
        this.props.doneRefreshPage();
      }
    });
  };

  get clientsInfo() {
    const clientIds = this.props.clients.selectedIds.join(',');
    const clients = this.props.clients.selectedIds.map((id) => this.props.clients.byId[id]);
    return {
      clientIds,
      clients,
      features: clients[0].features || {},
    };
  }

  isBrandsAccount = () => this.props.clients.selectedIds.length > 1;

  handlePrintPDF = () => {
    let displayTitle = '';
    const { companyName, brands = '' } = this.props.clients.byId[this.props.clients.selectedIds[0]];
    displayTitle = this.isBrandsAccount() ? `${brands}_chain` : companyName;
    const printWindow = window.open('', '', 'height=800,width=800');
    printWindow.document.write(`<html><head><title>${displayTitle}</title>`);
    printWindow.document.write('</head><body >');
    printWindow.document.write(this.state.iframeHtml);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  handleDownloadReport = async () => {
    let displayTitle = '';
    const { companyName, brands = '' } = this.props.clients.byId[this.props.clients.selectedIds[0]];
    displayTitle = this.isBrandsAccount() ? `${brands}_chain` : companyName;
    try {
      await this.props.fetchReportCSV(
        getDateFormat(this.state.startDate),
        getDateFormat(this.state.endDate),
        displayTitle
      );
    } catch (error) {
      alert('csv download failed, please try again later');
      console.log(error);
    }
  };

  handleDownloadReviewReport = async () => {
    let displayTitle = '';
    const { companyName, brands = '' } = this.props.clients.byId[this.props.clients.selectedIds[0]];
    displayTitle = this.isBrandsAccount() ? `${brands}_chain` : companyName;
    try {
      await this.props.fetchReviewReportXlsx(
        getDateFormat(this.state.startDate),
        getDateFormat(this.state.endDate),
        displayTitle
      );
    } catch (error) {
      alert('review report download failed, please try again later');
      console.log(error);
    }
  };

  handleDownloadSurveyReport = async () => {
    const { companyName, id: clientId } = this.props.clients.byId[this.props.clients.selectedIds[0]];
    try {
      const res = await this.props.fetchSurveyCSV({
        startTimeString: new Date(this.state.startDate).getTime(),
        endTimeString: new Date(this.state.endDate).getTime(),
        clientId,
      });
      this.downloadCSV(res.displayText, t('customerSurveyResultsForCompanyName', { companyName }));
    } catch (error) {
      alert('csv download failed, please try again later');
      console.log(error);
    }
  };

  handleDownloadFeedbackReport = async () => {
    let displayTitle = '';
    const { companyName, brands = '' } = this.props.clients.byId[this.props.clients.selectedIds[0]];
    displayTitle = this.isBrandsAccount() ? `${brands}_chain` : companyName;
    try {
      await this.props.fetchFeedbackCSV(
        getDateFormat(this.state.startDate),
        getDateFormat(this.state.endDate),
        displayTitle
      );
    } catch (error) {
      alert('csv download failed, please try again later');
      console.log(error);
    }
  };

  handleDownloadPnpReport = async () => {
    try {
      const { companyName } = this.props.clients.byId[this.props.clients.selectedIds[0]];
      const startDate = this.state.startDate.toDate();
      const endDate = this.state.endDate.toDate();
      const { data } = await this.props.fetchPnpAppointmentDeliveryList({
        startDate,
        endDate,
      });
      const dataToDownload = convertPnpKeyToTitle(data);
      const titleSuffix = t('notificationMessagesForDateRange', {
        start: startDate.toLocaleDateString(),
        end: endDate.toLocaleDateString(),
      });
      const displayTitle = `${companyName}_${titleSuffix}.xlsx`;
      json2xlsx(dataToDownload, t('notificationMessages'), displayTitle);
    } catch (error) {
      alert('pnp download failed, please try again later');
      console.log(error);
    }
  };

  handleOpenReport = async () => {
    if (this.state.isShowReport && !this.props.isRefresh) {
      return;
    }
    const { clientIds, clients } = this.clientsInfo;
    const { startDate, endDate } = this.state;

    const url = await getMonthlyReportIframeUrl({
      clientIds,
      startDate,
      endDate,
      clients,
    });
    this.setState({
      isShowReport: true,
      isloadingIframe: true,
      iframeHeight: 0,
      iframeSrc: url,
    });
  };

  downloadCSV = (csv, filename) => {
    const csvFile = new Blob([`\ufeff${csv}`], { type: 'text/plain;charset=utf-8' });
    const downloadLink = document.createElement('a');

    downloadLink.download = `${filename}-${new Date().toLocaleDateString()}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  renderReport = ({
    isRefresh,
    isloadingIframe,
    iframeHeight,
    clientSurvey,
    clientIds,
    startDate,
    endDate,
    enableSMSNotify,
    enablePNPNotify,
  }) => (
    <div>
      {(isRefresh || isloadingIframe) && <Spinner style={{ margin: '10px' }} name="circle" />}
      <Button
        style={{ margin: '10px' }}
        color="secondary"
        className="float-right"
        hidden={!iframeHeight}
        onClick={this.handlePrintPDF}
      >
        {t('downloadIntervalPerformanceReport')}
      </Button>
      <Button
        style={{ margin: '10px' }}
        color="secondary"
        className="float-right"
        hidden={!iframeHeight}
        onClick={this.handleDownloadReport}
      >
        {t('downloadIntervalCustomerList')}
      </Button>
      <Button
        style={{ margin: '10px' }}
        color="secondary"
        className="float-right"
        hidden={!iframeHeight}
        onClick={this.handleDownloadReviewReport}
      >
        {t('downloadIntervalReviewList')}
      </Button>
      <Button
        style={{ margin: '10px' }}
        color="secondary"
        className="float-right"
        hidden={!iframeHeight}
        onClick={this.handleDownloadFeedbackReport}
      >
        {t('downloadCustomerFeedbackList')}
      </Button>
      <Button
        style={{ margin: '10px' }}
        color="secondary"
        className="float-right"
        hidden={!iframeHeight || clientSurvey == null}
        onClick={this.handleDownloadSurveyReport}
      >
        {t('downloadCustomerListSurveyResults')}
      </Button>
      <Button
        style={{ margin: '10px' }}
        color="secondary"
        className="float-right"
        hidden={!iframeHeight || this.isBrandsAccount() || (!enableSMSNotify && !enablePNPNotify)}
        onClick={this.handleDownloadPnpReport}
      >
        {t('downloadNotificationMessageList')}
      </Button>
      <iframe
        key={`${clientIds}-${startDate}-${endDate}`}
        width="100%"
        height={iframeHeight}
        title="iframe"
        scrolling="no"
        src={this.state.iframeSrc}
        frameBorder="0"
      />
    </div>
  );

  render() {
    const {
      survey: clientSurvey,
      features: { enablePNPNotify, enableSMSNotify },
    } = this.props.clients.byId[this.props.clients.selectedIds[0]];
    const clientIds = this.props.clients.selectedIds.join(',');
    const { isShowReport, isloadingIframe, iframeHeight, startDate, endDate } = this.state;
    const { isRefresh } = this.props;

    return (
      <div className="animated fadeIn">
        <Row className="mt-4">
          <Col>
            <Card>
              <CardHeader>{t('chooseReportPeriod')}</CardHeader>
              <CardBody>
                <Row>
                  <Col sm="5">
                    <DateRangePicker
                      isOutsideRange={(day) => day.isAfter(moment())}
                      displayFormat="YYYY-MM-DD"
                      startDateId="startDateId"
                      startDate={this.state.startDate}
                      endDateId="endDateId"
                      endDate={this.state.endDate}
                      onDatesChange={({ startDate: startDateFromPicker, endDate: endDateFromPicker }) => {
                        this.setState({
                          startDate: startDateFromPicker,
                          endDate: endDateFromPicker,
                          isShowReport: false,
                        });
                      }}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) => {
                        this.setState({ focusedInput });
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      style={{ margin: '10px' }}
                      color="primary"
                      className="float-right"
                      onClick={this.handleOpenReport}
                    >
                      {t('openReport')}
                    </Button>
                  </Col>
                </Row>
                {isShowReport && (
                  <Row>
                    <Col sm="12">
                      {this.renderReport({
                        isRefresh,
                        isloadingIframe,
                        iframeHeight,
                        clientSurvey,
                        clientIds,
                        startDate,
                        endDate,
                        enablePNPNotify,
                        enableSMSNotify,
                      })}
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
