import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { addAlert, removeAlert } from 'reducers/ui';
import { handleLogout } from 'reducers/auth';
import './App.css';
import { ENV_STAGE, GOOGLE_ANNOUNCEMENT_SHEET_ID, GOOGLE_SHEET_API_KEY } from 'helpers';
import { fetchGoogleSheetsData } from 'google-sheets-mapper';
import Header from './components/Header/Header';
import {
  MemberList,
  MemberForm,
  MemberMerge,
  MemberImport,
  TagList,
  TagForm,
  AgeTagForm,
  GroupForm,
  GroupList,
} from './feature/MemberCenter';
import {
  TemplateList,
  TemplateForm,
  GroupMessageList,
  GroupMessageForm,
  PatientTrackingList,
  PatientTrackingForm,
} from './feature/MessageCenter';
import Customers from './components/Customers';
import Appointments from './components/Appointments';
import WesternAppointments from './components/WesternAppointments';
import Message from './components/Message';
import Report from './components/Report/';
import Response from './components/Response/';
import Passcode from './components/Passcode/';
import ReviewSetting from './components/ReviewSetting/';
import FlexEditor from './components/FlexEditor/';
import Platforms from './components/Platforms/Platforms';
import Line from './components/Line';
import Sidebar from './components/Sidebar/';
import { isLogin } from './helpers';
import theme from './theme';
import Home from 'components/Home';

const mapStateToProps = (state) => ({
  nav: state.nav,
  alerts: state.ui.alerts,
  selectedIds: state.clients.selectedIds,
});

const mapDispatchToProps = {
  handleLogout,
  addAlert,
  removeAlert,
};

const AlertWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 12px;
  right: 30px;
  bottom: 50px;
  z-index: 1;
`;

@connect(mapStateToProps, mapDispatchToProps)
export default class App extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    handleLogout: PropTypes.func,
    addAlert: PropTypes.func,
    removeAlert: PropTypes.func,
    alerts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        message: PropTypes.string,
      })
    ),
    nav: PropTypes.shape({
      items: PropTypes.array,
    }),
  };

  componentDidUpdate(prevProps) {
    const isBrand = JSON.parse(localStorage.getItem('auth'))?.user?.authRole === 'brand';
    if (isBrand && JSON.stringify(prevProps.selectedIds) !== JSON.stringify(this.props.selectedIds)) {
      this.props.history.push('/');
    }
  }

  componentDidMount() {
    if (isLogin()) {
      fetchGoogleSheetsData({
        apiKey: GOOGLE_SHEET_API_KEY,
        sheetId: GOOGLE_ANNOUNCEMENT_SHEET_ID,
        sheetsOptions: [{ id: ENV_STAGE }],
      }).then((res) => {
        const { data } = res[0];
        data.forEach((item) => {
          if (item.content && new Date(item.endDate) > Date.now()) {
            this.props.addAlert({
              type: 'announcement',
              message: item.content,
            });
          }
        });
      });
    } else {
      this.props.handleLogout();
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div className="app">
            <Header />
            <div className="app-body">
              <Sidebar {...this.props} />
              <main className="main">
                <Container fluid>
                  <Switch>
                    <Route
                      path="/home"
                      name="Home"
                      render={(props) => <Home newInfos={this.props.alerts} {...props} />}
                    />
                    {/* Member Group */}
                    <Route
                      path="/members/groups/create"
                      name="MemberGroupCreate"
                      render={(props) => <GroupForm mode="create" {...props} />}
                    />
                    <Route
                      path="/members/groups/:id"
                      name="MembersGroupEdit"
                      render={(props) => <GroupForm mode="edit" {...props} />}
                    />
                    <Route path="/members/groups" name="MemberGroupList" component={GroupList} />
                    {/* Member Tag */}
                    <Route
                      path="/members/ageTag/create"
                      name="MembersFilterCreate"
                      render={(props) => <AgeTagForm mode="create" {...props} />}
                    />
                    <Route
                      path="/members/ageTag/:id"
                      name="MembersFilterEdit"
                      render={(props) => <AgeTagForm mode="edit" {...props} />}
                    />
                    <Route
                      path="/members/tags/create"
                      name="MembersTagCreate"
                      render={(props) => <TagForm mode="create" {...props} />}
                    />
                    <Route
                      path="/members/tags/:id"
                      name="MembersTagEdit"
                      render={(props) => <TagForm mode="edit" {...props} />}
                    />
                    <Route path="/members/tags" name="MemberTags" component={TagList} />
                    <Route path="/members/import" name="MemberImport" component={MemberImport} />
                    {/* Members */}
                    <Route path="/members/merge" name="MemberMerge" render={(props) => <MemberMerge {...props} />} />
                    <Route
                      path="/members/create"
                      name="MemberCreate"
                      render={(props) => <MemberForm mode="create" {...props} />}
                    />
                    <Route
                      path="/members/:id"
                      name="MemberEdit"
                      render={(props) => <MemberForm mode="edit" {...props} />}
                    />
                    <Route path="/members" name="Members" component={MemberList} />
                    {/* Message Center */}
                    {/* Group Message */}
                    <Route
                      path="/message_center/group_message/create"
                      name="CreateGroupMessage"
                      render={(props) => <GroupMessageForm mode="create" {...props} />}
                    />
                    <Route
                      path="/message_center/group_message/:id"
                      name="EditGroupMessage"
                      render={(props) => <GroupMessageForm mode="edit" {...props} />}
                    />
                    <Route path="/message_center/group_message" name="GroupMessageList" component={GroupMessageList} />
                    {/* Patient Tracking */}
                    <Route
                      path="/message_center/patient_tracking/create"
                      name="CreatePatientTracking"
                      render={(props) => <PatientTrackingForm mode="create" {...props} />}
                    />
                    <Route
                      path="/message_center/patient_tracking/:id"
                      name="EditPatientTracking"
                      render={(props) => <PatientTrackingForm mode="edit" {...props} />}
                    />
                    <Route
                      path="/message_center/patient_tracking"
                      name="PatientTrackingList"
                      component={PatientTrackingList}
                    />
                    {/* Template */}
                    <Route
                      path="/message_center/templates/create_groupMessage"
                      name="CreateTemplate"
                      render={(props) => <TemplateForm mode="create" type="groupMessage" {...props} />}
                    />
                    <Route
                      path="/message_center/templates/edit_groupMessage/:id"
                      name="EditTemplate"
                      render={(props) => <TemplateForm mode="edit" type="groupMessage" {...props} />}
                    />
                    <Route
                      path="/message_center/templates/create_patientTracking"
                      name="CreateTemplate"
                      render={(props) => <TemplateForm mode="create" type="patientTracking" {...props} />}
                    />
                    <Route
                      path="/message_center/templates/edit_patientTracking/:id"
                      name="EditTemplate"
                      render={(props) => <TemplateForm mode="edit" type="patientTracking" {...props} />}
                    />
                    <Route path="/message_center/templates" name="TemplateList" component={TemplateList} />
                    {/* V1 Features */}
                    <Route path="/customers" name="Customers" component={Customers} />
                    <Route path="/appointments" name="Appointments" component={Appointments} />
                    <Route path="/westernappointments" name="WesternAppointments" component={WesternAppointments} />
                    <Route path="/report" name="Report" component={Report} />
                    <Route path="/response" name="Response" component={Response} />
                    <Route path="/platforms" name="Platforms" component={Platforms} />
                    <Route path="/linemessage" name="Line" component={Line} />
                    <Route path="/message" name="Message" component={Message} />
                    <Route path="/Passcode" name="Passcode" component={Passcode} />
                    <Route path="/flexeditor" name="FlexEditor" component={FlexEditor} />
                    <Route path="/reviewSetting" name="ReviewSetting" component={ReviewSetting} />
                    <Redirect from="/" to="/home" />
                  </Switch>
                </Container>
              </main>
            </div>
          </div>
        </ThemeProvider>
      </StylesProvider>
    );
  }
}
