import React from 'react';
import PropTypes from 'prop-types';
import ImageRenderer from './ImageRenderer';
import {
  ContentBox,
  ContentButton,
  ContentTextContainer,
  ContentTextP,
  ContentSpan,
  Spacer,
  ContentSeparater,
  OuterBox,
  BodyOuterBox,
  HeroOuterBox,
  FooterOuterBox,
} from './styledComponents';
import { orderJsonEntries } from '../Editor/ComponentEditors/MainKeys';

const defaultMainStyles = {};

const contentRenderer = (content, index) => {
  switch (content.type) {
    case 'text':
      // 如果 text 下有 span 就需要此
      if (content.contents && content.contents.length > 0) {
        const { contents: contentsInText } = content;
        return (
          <ContentTextContainer {...content}>
            <ContentTextP {...content}>{contentsInText.map(contentRenderer)}</ContentTextP>
          </ContentTextContainer>
        );
      }
      return (
        <ContentTextContainer key={index} {...content}>
          <ContentTextP {...content}>{content.text}</ContentTextP>
        </ContentTextContainer>
      );
    case 'span': {
      return <ContentSpan {...content}>{content.text}</ContentSpan>;
    }
    case 'button': {
      return (
        <ContentButton
          height={content.height}
          buttonStyle={content.style}
          color={content.color}
          onClick={() => {
            console.log('style in button: ', content.style);
          }}
        >
          {content.action.label}
        </ContentButton>
      );
    }
    case 'box': {
      const { contents: contentsInBox } = content;
      return <ContentBox {...content}>{contentsInBox.map(contentRenderer)}</ContentBox>;
    }
    case 'separator': {
      return <ContentSeparater />;
    }
    case 'spacer': {
      return <Spacer />;
    }
    case 'image': {
      return <ImageRenderer content={content} />;
    }
    default:
      return null;
  }
};

function BubbleRenderer({ json }) {
  const sortMainKeyOrder = Object.entries(json).sort(orderJsonEntries);
  return sortMainKeyOrder.map(([key, value], i) => {
    const { contents, type } = value;
    const mainStyles = json.styles ? json.styles[key] : defaultMainStyles;
    const mainStylesInline = json[key] ? json[key] : defaultMainStyles;
    if (key === 'body' && contents !== null) {
      return (
        <BodyOuterBox
          {...mainStyles}
          {...mainStylesInline}
          onClick={(e) => {
            console.log('e.target:', e.target, 'key:', key, value, json, mainStylesInline);
          }}
          key={i}
        >
          {contents.map(contentRenderer)}
        </BodyOuterBox>
      );
    } else if (key === 'hero' && contents != null) {
      return (
        <HeroOuterBox
          {...mainStyles}
          {...mainStylesInline}
          onClick={(e) => {
            console.log('e.target:', e.target, 'key:', key, value, json, mainStylesInline);
          }}
          key={i}
        >
          {contents.map(contentRenderer)}
        </HeroOuterBox>
      );
    } else if (key === 'footer' && contents != null) {
      return (
        <FooterOuterBox
          {...mainStyles}
          {...mainStylesInline}
          onClick={(e) => {
            console.log('e.target:', e.target, 'key:', key, value, json, mainStylesInline);
          }}
          key={i}
        >
          {contents.map(contentRenderer)}
        </FooterOuterBox>
      );
    } else if (contents != null) {
      return (
        <OuterBox
          {...mainStyles}
          {...mainStylesInline}
          onClick={(e) => {
            console.log('e.target:', e.target, 'key:', key, value, json, mainStylesInline);
          }}
          key={i}
        >
          {contents.map(contentRenderer)}
        </OuterBox>
      );
    }
    if (type === 'image') {
      return <ImageRenderer content={value} key={i} />;
    }
    return null;
  });
}

BubbleRenderer.propTypes = {
  json: PropTypes.object,
};

export default BubbleRenderer;
