import React, { memo, useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import { dashboard_v2 } from 'theme';
import { getPatientTrackings } from 'reducers/members';
import { t } from 'i18n/config';
import PatientTrackingTag from 'components/Dashboard_v2/PatientTrackingTag';

const { colors } = dashboard_v2;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 400px;
  width: 100%;
  background: ${colors.SHADES_000};
  border: 1px solid ${colors.SHADES_400};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  i {
    margin-left: 8px;
    color: ${colors.SHADES_400};
  }
`;

const SelectedCount = styled.div`
  font-size: 12px;
  color: ${colors.SHADES_400};
  padding: 16px 16px 0 16px;
  margin-bottom: -4px;
`;

const PopoverWrapper = styled.div`
  width: 400px;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: ${({ selectedWrapper }) => (selectedWrapper ? '0' : '16px')};
  font-size: 12px;
  input {
    flex: 1;
    font-size: 16px;
    border: none;
    min-width: ${({ hasTag }) => (hasTag ? '100px' : '255px')};
    width: 100%;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${colors.SHADES_400};
    }
    :-ms-input-placeholder {
      color: ${colors.SHADES_400};
    }
  }
`;

const PatientTrackingSelectorInput = ({ selectedPatientTrackings = [], setSelectedPatientTrackings }) => {
  const dispatch = useDispatch();
  const [clientId] = useSelector((state) => state.clients.selectedIds);
  const taggablePatientTrackings = useSelector((state) => state.member.taggablePatientTrackings);
  const inputContainerRef = useRef();
  const keywordInputRef = useRef();
  const [keyword, setKeyword] = useState('');
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);

  const filteredPatientTrackings = useMemo(() => {
    if (!keyword) {
      return taggablePatientTrackings;
    }
    return taggablePatientTrackings.filter((tracking) =>
      tracking.title?.toLocaleLowerCase().includes(keyword?.toLocaleLowerCase())
    );
  }, [taggablePatientTrackings, keyword]);

  const inputFocus = useCallback(() => {
    keywordInputRef.current.focus();
  }, []);

  const openSelector = useCallback(() => {
    setIsSelectorOpen(true);
    inputFocus();
  }, [inputFocus]);

  const getSelectedTagIndex = (tracking) =>
    selectedPatientTrackings.findIndex((selectedTracking) => selectedTracking.title === tracking.title);

  const removeTag = (tagIndex) => {
    setSelectedPatientTrackings((prev) => {
      const newArray = [...prev];
      newArray.splice(tagIndex, 1);
      return newArray;
    });
  };

  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (!isSelectorOpen) {
        openSelector();
      }
      setKeyword(value);
    },
    [isSelectorOpen, openSelector]
  );

  const handleSelect = (tracking) => {
    const tagIndex = getSelectedTagIndex(tracking);
    if (tagIndex === -1) {
      setSelectedPatientTrackings((prev) => [...prev, tracking]);
    } else {
      removeTag(tagIndex);
    }
  };

  const handleRemove = (e, tracking) => {
    e.stopPropagation();
    const tagIndex = getSelectedTagIndex(tracking);
    if (tagIndex !== -1) {
      removeTag(tagIndex);
    }
  };

  useEffect(() => {
    dispatch(getPatientTrackings({ clientId }));
  }, [dispatch, clientId]);

  return (
    <div>
      <InputContainer ref={inputContainerRef} onClick={openSelector}>
        <TagsWrapper selectedWrapper onClick={inputFocus} hasTag={selectedPatientTrackings.length > 0}>
          {selectedPatientTrackings.map((tracking) => (
            <PatientTrackingTag
              name={tracking.title}
              setting={{
                isExpired: Date.now() > new Date(tracking.expiredAt),
                isEnabled: tracking.status === 'enabled',
                recyclable: tracking.recyclable,
              }}
              onClick={(e) => e.stopPropagation()}
              onClickRemove={(e) => handleRemove(e, tracking)}
              key={tracking.id}
            />
          ))}
          <input
            ref={keywordInputRef}
            value={keyword}
            onChange={handleInputChange}
            placeholder={selectedPatientTrackings.length ? '' : t('searchPatientTracking')}
            type="text"
          />
        </TagsWrapper>
        <i className="ri-arrow-down-s-line" />
      </InputContainer>

      {isSelectorOpen && (
        <Popover
          open={isSelectorOpen || keyword}
          anchorEl={inputContainerRef.current}
          onClose={() => setIsSelectorOpen(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{
            marginTop: '8px',
            maxHeight: '300px',
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
        >
          <PopoverWrapper>
            <SelectedCount>
              {t('searchResults', { info: `${filteredPatientTrackings.length}/${taggablePatientTrackings.length}` })}
            </SelectedCount>
            <TagsWrapper>
              {filteredPatientTrackings.map((tracking) => (
                <PatientTrackingTag
                  name={tracking.title}
                  setting={{
                    isExpired: Date.now() > new Date(tracking.expiredAt),
                    isEnabled: tracking.status === 'enabled',
                    recyclable: tracking.recyclable,
                  }}
                  onClick={() => handleSelect(tracking)}
                  isSelected={getSelectedTagIndex(tracking) !== -1}
                  key={tracking.id}
                />
              ))}
            </TagsWrapper>
          </PopoverWrapper>
        </Popover>
      )}
    </div>
  );
};

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedMemberIds: PropTypes.array,
};

PatientTrackingSelectorInput.propTypes = propTypes;

export default memo(PatientTrackingSelectorInput);
