import { t } from 'i18n/config';
import { ICON_TYPES } from 'components/icons';

export default {
  items: [
    {
      name: t('nav.home'),
      url: '/home',
      icon: ICON_TYPES.Home,
    },
    {
      name: t('memberManagement'),
      url: '/members',
      icon: ICON_TYPES.HiEmojiHappy,
      feature: 'userManagement',
    },
    {
      name: t('messageCenter'),
      url: '/message_center/group_message',
      icon: ICON_TYPES.HiPaperAirplane,
      feature: 'userManagement',
    },
    {
      name: t('lineSendMessage'),
      url: '/linemessage',
      icon: ICON_TYPES.Line,
    },
    {
      name: t('ratingDispatch'),
      url: '/customers',
      icon: ICON_TYPES.HiStar,
    },
    {
      name: t('appointmentCenter'),
      category: 'dental',
      url: '/appointments',
      icon: ICON_TYPES.HiClock,
    },
    {
      name: t('registrationCenter'),
      category: 'western',
      url: '/westernappointments',
      icon: ICON_TYPES.HiClock,
    },
    {
      name: t('respondToCustomers'),
      url: '/response',
      icon: ICON_TYPES.HiReply,
    },
    {
      name: t('mobileVerification'),
      feature: 'passcode',
      url: '/passcode',
      icon: ICON_TYPES.HiOutlineDeviceMobile,
    },
    {
      name: t('sendMessage'),
      category: 'dental',
      url: '/message',
      icon: ICON_TYPES.HiChat,
    },
    {
      name: t('reportDownload'),
      url: '/report',
      icon: ICON_TYPES.HiDocumentReport,
    },
    {
      name: t('ratingSetting'),
      url: '/reviewSetting',
      icon: ICON_TYPES.HiPencil,
    },
    {
      name: t('autoReplyMessage'),
      url: '/flexEditor',
      icon: ICON_TYPES.HiTemplate,
    },
    {
      name: t('platformAuthorization'),
      url: '/platforms',
      icon: ICON_TYPES.HiShieldCheck,
    },
    {
      name: t('logout'),
      url: '/login',
      icon: ICON_TYPES.HiOutlineLogout,
    },
  ],
};

export const demoNav = {
  items: [
    {
      name: t('nav.home'),
      url: '/home',
      icon: ICON_TYPES.Home,
    },
    {
      name: t('memberManagement'),
      url: '/members',
      icon: ICON_TYPES.HiEmojiHappy,
      feature: 'userManagement',
    },
    {
      name: t('messageCenter'),
      url: '/message_center/group_message',
      icon: ICON_TYPES.HiPaperAirplane,
      feature: 'userManagement',
    },
    {
      name: t('lineSendMessage'),
      url: '/linemessage',
      icon: ICON_TYPES.Line,
    },
    {
      name: t('ratingDispatch'),
      url: '/customers',
      icon: ICON_TYPES.HiStar,
    },
    {
      name: t('appointmentCenter'),
      category: 'dental',
      url: '/appointments',
      icon: ICON_TYPES.HiClock,
    },
    {
      name: t('registrationCenter'),
      category: 'western',
      url: '/westernappointments',
      icon: ICON_TYPES.HiClock,
    },

    {
      name: t('respondToCustomers'),
      url: '/response',
      icon: ICON_TYPES.HiReply,
    },
    {
      name: t('mobileVerification'),
      feature: 'passcode',
      url: '/passcode',
      icon: ICON_TYPES.HiOutlineDeviceMobile,
    },
    {
      name: t('reportDownload'),
      url: '/report',
      icon: ICON_TYPES.HiDocumentReport,
    },
    {
      name: t('platformAuthorization'),
      url: '/platforms',
      icon: ICON_TYPES.HiShieldCheck,
    },
    {
      name: t('logout'),
      url: '/login',
      icon: ICON_TYPES.HiOutlineLogout,
    },
  ],
};
