import { VendorCategory } from 'utils/vendors';
import { ENV_STAGE } from 'helpers';

const isPathHidden = (features, route) => route.feature && !features[route.feature];
const matchCategory = (vendor, route) => route.category && VendorCategory[vendor] === route.category;

// For totally hiding the route from sidebar.
export const filterByFeatures = (client) => (route) => !isPathHidden(client.features, route);

// ignore filter if route isn't category related
export const filterByCategory = (client) => (route) => {
  if (!route.category) {
    return true;
  }
  return matchCategory(client.vendor, route);
};

export const filterForMainStore = () => (route) => {
  const hideList = [
    '/members',
    '/members/tags',
    '/members/group',
    '/message_center/group_message',
    '/message_center/patient_tracking',
    '/message_center/templates',
    '/chat',
  ];
  return !hideList.includes(route.url);
};

export const filterByVendor = (client) => (route) => {
  const vendor = client.vendor;
  const hideListByVendor = {
    medley: [
      '/linemessage', // LINE傳送訊息
      '/appointments', // 預約中心
      '/westernappointments', // 掛號中心
      '/message', // 發送訊息
      '/chat', // 線上諮詢
    ],
    linkedcare: [
      '/linemessage', // LINE傳送訊息
      '/appointments', // 預約中心
      '/westernappointments', // 掛號中心
      '/message', // 發送訊息
      '/chat', // 線上諮詢
      '/passcode', // 手機驗證
    ],
  };

  if (hideListByVendor[vendor]?.includes(route.url)) {
    return false;
  }

  return true;
};
