import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BubbleRenderer from './BubbleRenderer';

const bubbleSizeMap = {
  giga: '360px',
  mega: '300px',
  kilo: '260px',
  hecto: '240px',
  deca: '220px',
  micro: '160px',
  nano: '120px',
};

const CarouselContainer = styled.div`
  margin: 9px 0x;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  z-index: -2;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const BubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 15px;
  margin: 9px;
  width: ${(props) => bubbleSizeMap[props.size] || '300px'};
  overflow: hidden;
  flex-shrink: 0;
`;

function MessageRenderer({ json }) {
  return (
    <React.Fragment>
      {!Array.isArray(json) && json !== null && (
        <CarouselContainer>
          {json && json.type === 'bubble' && (
            <BubbleContainer size={json.size}>
              <BubbleRenderer json={json}></BubbleRenderer>
            </BubbleContainer>
          )}
          {json.type === 'carousel' &&
            json.contents.map((item, index) => (
              <BubbleContainer key={`${index}_${index}`}>
                <BubbleRenderer json={item} size={json.size}></BubbleRenderer>
              </BubbleContainer>
            ))}
        </CarouselContainer>
      )}
    </React.Fragment>
  );
}

MessageRenderer.propTypes = {
  json: PropTypes.object,
};

export default MessageRenderer;
