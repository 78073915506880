import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextFieldWithFlexVar from '../../utilsComponents/TextFieldWithFlexVar';
import Text from 'components/Dashboard_v2/Text';
import { t } from 'i18n/config';

const Container = styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;
`;
function TextEditor({ content, onChange }) {
  const handleChange = (changes) => {
    onChange({
      ...content,
      ...changes,
    });
    console.log('changes:', changes);
  };
  return (
    <Container>
      <Text font="Heading/Large/Medium" marginBottom={20} color="SHADES_700">
        {t('flexEditor.textMessage')}
      </Text>
      <TextFieldWithFlexVar name="text" value={content.text} onChange={handleChange} />
    </Container>
  );
}

TextEditor.propTypes = {
  content: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default TextEditor;
