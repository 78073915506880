import {
  HiPencil,
  HiDocumentReport,
  HiChat,
  HiOutlineDeviceMobile,
  HiPaperAirplane,
  HiEmojiHappy,
  HiStar,
  HiClock,
  HiReply,
  HiTemplate,
  HiShieldCheck,
  HiOutlineLogout,
  HiHome,
} from 'react-icons/hi';
import { RiLineFill, RiSpeedUpLine, RiNotificationFill, RiQuestionnaireFill } from 'react-icons/ri';

export const ICON_TYPES = {
  HiEmojiHappy: 'hiEmojiHappy',
  HiPaperAirplane: 'hiPaperAirplane',
  HiStar: 'hiStar',
  HiClock: 'hiClock',
  HiOutlineDeviceMobile: 'hiOutlineDeviceMobile',
  HiChat: 'hiChat',
  HiDocumentReport: 'hiDocumentReport',
  HiPencil: 'hiPencil',
  HiReply: 'hiReply',
  HiShieldCheck: 'hiShieldCheck',
  HiTemplate: 'hiTemplate',
  HiOutlineLogout: 'hiOutlineLogout',
  Line: 'Line',
  RiSpeedUpLine: 'riSpeedUpLine',
  RiNotificationFill: 'riNotificationFill',
  RiQuestionnaireFill: 'riQuestionnaireFill',
  Home: 'home',
};

export const getIcons = (name, props) => {
  switch (name) {
    case ICON_TYPES.HiEmojiHappy:
      return <HiEmojiHappy {...props} />;
    case ICON_TYPES.HiPaperAirplane:
      return <HiPaperAirplane {...props} />;
    case ICON_TYPES.HiStar:
      return <HiStar {...props} />;
    case ICON_TYPES.HiClock:
      return <HiClock {...props} />;
    case ICON_TYPES.HiOutlineDeviceMobile:
      return <HiOutlineDeviceMobile {...props} />;
    case ICON_TYPES.HiChat:
      return <HiChat {...props} />;
    case ICON_TYPES.HiDocumentReport:
      return <HiDocumentReport {...props} />;
    case ICON_TYPES.HiPencil:
      return <HiPencil {...props} />;
    case ICON_TYPES.HiReply:
      return <HiReply {...props} />;
    case ICON_TYPES.HiShieldCheck:
      return <HiShieldCheck {...props} />;
    case ICON_TYPES.HiTemplate:
      return <HiTemplate {...props} />;
    case ICON_TYPES.HiOutlineLogout:
      return <HiOutlineLogout {...props} />;
    case ICON_TYPES.Line:
      return <RiLineFill {...props} />;
    case ICON_TYPES.RiSpeedUpLine:
      return <RiSpeedUpLine {...props} />;
    case ICON_TYPES.RiNotificationFill:
      return <RiNotificationFill {...props} />;
    case ICON_TYPES.RiQuestionnaireFill:
      return <RiQuestionnaireFill {...props} />;
    case ICON_TYPES.Home:
      return <HiHome {...props} />;
    default:
      return null;
  }
};
