import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import ListGroup from './listgroup';
import TextEditor from 'components/FlexEditor/contentformat/messagesEditor/componentEditors/TextEditor';
import ImageEditor from 'components/FlexEditor/contentformat/messagesEditor/componentEditors/ImageEditor';
import FlexSimulator from 'components/FlexEditor/contentformat/FlexMessage/FlexSimulator';
import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import useAlert from 'hooks/useAlert';
import { t } from 'i18n/config';
import { getLineAction, upsertLineAction } from 'components/FlexEditor/api/setting';

const { colors } = dashboard_v2;

const Container = styled.div`
  display: flex;
  margin: 0 15px 0 -30px;
  overflow-x: auto;
`;
const WorkingMessageWrapper = styled.div`
  margin: 16px;
  margin-left: 230px;
`;
const WorkingMessageIndexContainer = styled.div`
  position: relative;
  min-height: 120px;
  margin-top: 8px;
  width: 1150px;
  border: 1px solid ${colors.SHADES_500};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > i.ri-close-circle-fill {
    position: absolute;
    top: -2px;
    right: 5px;
    font-size: 32px;
    color: ${colors.SHADES_500};
    cursor: pointer;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`;

function App({ clientId, vendor }) {
  const [lineActionsList, setLineActionsList] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [workingMessage, setWorkingMessage] = useState([]);
  const [applyStatus, setApplyStatus] = useState(false);
  const fetchLineAction = useCallback(async () => {
    try {
      const response = await getLineAction({ clientId, vendor });
      setLineActionsList(response.data);
    } catch (error) {
      console.error('Error fetching line action:', error);
    }
  }, [clientId, vendor]);
  const { renderAlert, setAlert } = useAlert();

  useEffect(() => {
    fetchLineAction();
  }, [fetchLineAction]);

  const handleRemove =
    ({ index }) =>
    () => {
      const contentCopy = JSON.parse(JSON.stringify(workingMessage));
      if (contentCopy.length <= 1) {
        return alert('此為最後項目，無法直接刪除');
      } else {
        const rest = contentCopy.filter((c, i) => i !== index);
        setWorkingMessage(rest);
        setApplyStatus(true);
      }
    };

  const handleWorkingMessageChange =
    ({ index }) =>
    (changes) => {
      const contentCopy = JSON.parse(JSON.stringify(workingMessage));
      contentCopy[index] = changes;
      setWorkingMessage(contentCopy);
      setApplyStatus(true);
    };

  const handleImageChange = (imageUrl, index) => {
    const newMessages = [...workingMessage];
    newMessages[index] = {
      ...newMessages[index],
      originalContentUrl: imageUrl,
      previewImageUrl: imageUrl,
    };
    setWorkingMessage(() => newMessages);
    setApplyStatus(true);
  };

  const handleChangeFlexObject = ({ contents }) => {
    const contentCopy = JSON.parse(JSON.stringify(workingMessage));
    contentCopy.contents = contents;
    setWorkingMessage(contentCopy);
    setApplyStatus(true);
  };

  const handleApplyClick = async () => {
    try {
      const response = await upsertLineAction({
        clientId,
        alias: selectedAction.alias,
        data: {
          message: workingMessage,
          quickReply: selectedAction.quickReply,
          smsMessage: selectedAction.smsMessage,
          lineChannelID: selectedAction.lineChannelID,
          messageTmpl: selectedAction.messageTmpl,
        },
        vendor,
      });
      if (response.success) {
        setAlert({ type: 'success', title: t('flexEditor.updateSuccess') });
        setApplyStatus(false);
        fetchLineAction();
      }
    } catch (error) {
      setAlert({ type: 'error', title: t('flexEditor.updateFailure') });
      fetchLineAction();
      console.error('Error fetching line action:', error);
    }
  };
  return (
    <Container>
      {renderAlert({ position: 'fixed', top: '8%' })}
      <ListGroup
        lineActions={lineActionsList || []}
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
        setWorkingMessage={setWorkingMessage}
        setApplyStatus={setApplyStatus}
        vendor={vendor}
      ></ListGroup>
      <WorkingMessageWrapper>
        {Array.isArray(workingMessage) &&
          workingMessage.map((content, index) => {
            if (content.type === 'sticker') {
              return null;
            }
            if (content.type === 'text') {
              return (
                <WorkingMessageIndexContainer key={index}>
                  <TextEditor content={content} onChange={handleWorkingMessageChange({ index })} />
                  <i className="ri-close-circle-fill" onClick={handleRemove({ index })} />
                </WorkingMessageIndexContainer>
              );
            }
            if (content.type === 'image') {
              return (
                <WorkingMessageIndexContainer key={index}>
                  <ImageEditor
                    data={content}
                    handleChange={(imageUrl) => handleImageChange(imageUrl, index)}
                  ></ImageEditor>
                  <i className="ri-close-circle-fill" onClick={handleRemove({ index })} />
                </WorkingMessageIndexContainer>
              );
            }
            if (content.type === 'flex') {
              return (
                <WorkingMessageIndexContainer key={index}>
                  <FlexSimulator flexJSON={content} onSave={handleWorkingMessageChange({ index })} />
                  <i className="ri-close-circle-fill" onClick={handleRemove({ index })} />
                </WorkingMessageIndexContainer>
              );
            }
            return (
              <WorkingMessageIndexContainer key={index}>此列為template畫面 請直接編輯json</WorkingMessageIndexContainer>
            );
          })}
        {workingMessage.type === 'flex' && (
          <WorkingMessageIndexContainer>
            <FlexSimulator flexJSON={workingMessage} onSave={handleChangeFlexObject} />
          </WorkingMessageIndexContainer>
        )}
        {workingMessage.length !== 0 && (
          <ActionButtons>
            <Button
              disabled={!applyStatus}
              color="primary"
              variant="filled"
              onClick={() => {
                handleApplyClick();
              }}
            >
              {t('flexEditor.applyMessage')}
            </Button>
          </ActionButtons>
        )}
      </WorkingMessageWrapper>
    </Container>
  );
}

export default App;
