import { getToken, MARKETING_URL, ENV_STAGE } from '../helpers';
import { responseHandler } from './responseHandler';

const getHeaders = () => ({
  Authorization: `Bearer ${getToken()}`,
  'AP-stage': ENV_STAGE,
});

/* Members */
export const fetchMembers = async ({ clientId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/listUsers`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const fetchMember = async ({ clientId, memberId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/users/${memberId}`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const createMember = async ({ clientId, member }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/users`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      user: member,
    }),
  }).then(responseHandler);

export const bulkUploadMembers = async ({ clientId, users, tags }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/bulkuploadUsers`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      users,
      tags,
    }),
  }).then(responseHandler);

export const updateMember = async ({ clientId, memberId, member }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/users/${memberId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify({
      user: member,
    }),
  }).then(responseHandler);

/* Tag */
export const fetchTags = async ({ clientId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/tags`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const fetchTag = async ({ clientId, tagId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/tags/${tagId}`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const createTag = async ({ clientId, tag }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/tags`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ tag }),
  }).then(responseHandler);

export const updateTag = async ({ clientId, tagId, tag }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/tags/${tagId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify({ tag }),
  }).then(responseHandler);

export const deleteTag = async ({ clientId, tagId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/tags/${tagId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  }).then(responseHandler);

export const assignTagToUsers = async ({ clientId, memberIds, searchQueries, tags }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/usersWithTags`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      userIds: memberIds,
      searchQueries,
      tags,
    }),
  }).then(responseHandler);

/* Filter */
export const fetchFilters = async ({ clientId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/filters`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const fetchFilter = async ({ clientId, filterId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/filters/${filterId}`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const createFilter = async ({ clientId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/filters`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const updateFilter = async ({ clientId, filterId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/filters/${filterId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const deleteFilter = async ({ clientId, filterId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/filters/${filterId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  }).then(responseHandler);

/* Group */
export const fetchFiltersAndTags = async ({ clientId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/filtersAndTags`, {
    headers: getHeaders(),
  }).then(responseHandler);

let predictAbortController;
export const abortGetGroupPredictUserCount = () => predictAbortController && predictAbortController.abort();
export const getGroupPredictUserCount = async ({ clientId, payload }) => {
  predictAbortController = new AbortController();
  const signal = predictAbortController.signal;
  return fetch(`${MARKETING_URL}/v2/clients/${clientId}/countUsers`, {
    method: 'POST',
    headers: getHeaders(),
    signal,
    body: JSON.stringify(payload),
  }).then(responseHandler);
};

export const fetchGroups = async ({ clientId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/groups`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const fetchGroup = async ({ clientId, groupId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/groups/${groupId}`, {
    headers: getHeaders(),
  }).then(responseHandler);

export const createGroup = async ({ clientId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/groups`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const updateGroup = async ({ clientId, groupId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/groups/${groupId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const deleteGroup = async ({ clientId, groupId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/groups/${groupId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  }).then(responseHandler);

/* Profile */
export const getUserPatientTrackings = async ({ clientId, userId }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/users/${userId}/patientTrackings`, {
    method: 'GET',
    headers: getHeaders(),
  }).then(responseHandler);

export const getUserPatientTrackingMessages = async ({ clientId, userId, patientTrackingId }) =>
  fetch(
    `${MARKETING_URL}/v2/clients/${clientId}/users/${userId}/patientTrackings/${patientTrackingId}/messageSchedulers`,
    {
      method: 'GET',
      headers: getHeaders(),
    }
  ).then(responseHandler);

/* Merge */
export const mergeUsers = async ({ clientId, payload }) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/users/mergeUsers`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  }).then(responseHandler);

export const getLatestMembersUploadProcess = (clientId) =>
  fetch(`${MARKETING_URL}/v2/clients/${clientId}/bulkuploadUsers/action-get-latest-operation`, {
    method: 'GET',
    headers: getHeaders(),
  }).then(responseHandler);
