import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CollapseContainer from './collapseContainer';
import { orderJsonEntries } from '../Editor/ComponentEditors/MainKeys';
import { dashboard_v2 } from 'theme';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const TreeContainer = styled.div`
  min-height: 480px;
  width: 100%;
  background: #f5f6fa;
  border: 1px solid ${colors.SHADES_400};
  border-radius: 4px;
`;
const InBoxContainer = styled.div`
  margin: 4px 4px;
`;

const contentRenderer =
  ({ selectedPath, handleSelect, parentPath }) =>
  (content, index) => {
    const key = `${parentPath}.contents[${index}]`;
    const path = `${parentPath}.contents[${index}]`;
    switch (content.type) {
      case 'bubble': {
        const { contents: contentsInBox } = content;
        return (
          <CollapseContainer
            key={key}
            title={t('flexEditor.bubble')}
            onSelect={handleSelect}
            content={content}
            selectedPath={selectedPath}
            path={path}
            parentPath={parentPath}
            index={index}
          >
            <InBoxContainer>
              {contentsInBox.map(
                contentRenderer({
                  selectedPath,
                  handleSelect,
                  parentPath: `${parentPath}.contents[${index}]`,
                })
              )}
            </InBoxContainer>
          </CollapseContainer>
        );
      }
      case 'box': {
        const { contents: contentsInBox } = content;
        return (
          <InBoxContainer key={key}>
            {contentsInBox.map(
              contentRenderer({
                selectedPath,
                handleSelect,
                parentPath: `${parentPath}.contents[${index}]`,
              })
            )}
          </InBoxContainer>
        );
      }
      case 'text':
        if (content.contents && content.contents.length !== 0) {
          // span render
          const { contents: contentsInText } = content;
          return (
            <InBoxContainer key={key}>
              {contentsInText.map(
                contentRenderer({
                  selectedPath,
                  handleSelect,
                  parentPath: `${parentPath}.contents[${index}]`,
                })
              )}
            </InBoxContainer>
          );
        } else if (
          content.text === 'hello, world' ||
          (content.text && content.text.length < 4 && (content.color === '#ffffff' || content.color === '#FFFFFF'))
        ) {
          return null;
        } else
          return (
            <CollapseContainer
              key={key}
              title={content.text || t('flexEditor.noEmpty')}
              onSelect={handleSelect}
              content={content}
              selectedPath={selectedPath}
              path={path}
              parentPath={parentPath}
              index={index}
            ></CollapseContainer>
          );
      case 'span':
        if (content.color === '#ffffff' || content.color === '#FFFFFF') {
          return null;
        } else {
          return (
            <CollapseContainer
              key={key}
              title={content.text || t('flexEditor.noEmpty')}
              onSelect={handleSelect}
              content={content}
              selectedPath={selectedPath}
              path={path}
              parentPath={parentPath}
              index={index}
            >
              <InBoxContainer>{content.text}</InBoxContainer>
            </CollapseContainer>
          );
        }
      case 'image': {
        return (
          <CollapseContainer
            key={index}
            title={t('image')}
            content={content}
            selectedPath={selectedPath}
            path={path}
            index={index}
            parentPath={parentPath}
            onSelect={handleSelect}
          ></CollapseContainer>
        );
      }
      case 'button': {
        return (
          <CollapseContainer
            key={key}
            title={content.action.label || t('flexEditor.noEmpty')}
            onSelect={handleSelect}
            content={content}
            selectedPath={selectedPath}
            path={path}
            parentPath={parentPath}
            index={index}
          ></CollapseContainer>
        );
      }
      default:
        return null;
    }
  };

function Tree({ json, onSelect, selectedPath }) {
  const handleSelect = ({ ref, content, path, index, parentPath }) => {
    onSelect({
      ref,
      content,
      path,
      parentPath,
      index,
    });
  };

  return (
    <TreeContainer>
      {json.type === 'carousel' ? (
        <CollapseContainer
          key="carousel"
          selectedPath=""
          onSelect={() => {
            onSelect({
              path: 'carousel',
              parentPath: '',
              index: 0,
              content: json,
            });
          }}
          content={{}}
          path="carousel"
          title={t('flexEditor.carousel')}
          parentPath=""
          index={0}
        >
          {json.contents.map((item, index) => {
            return (
              <CollapseContainer
                key={`contents[${index}]`}
                selectedPath={selectedPath}
                onSelect={handleSelect}
                content={{}}
                path={`contents[${index}]`}
                title={`第${index + 1}頁`}
                parentPath="carousel"
                index={index}
              >
                {Object.entries(item)
                  .sort(orderJsonEntries)
                  .map(([key, value], subIndex) => {
                    const { contents = [], type } = value;
                    if (key === 'styles') {
                      return null;
                    }
                    if (type === 'image') {
                      return (
                        <CollapseContainer
                          key={key}
                          selectedPath={selectedPath}
                          onSelect={handleSelect}
                          content={value}
                          path={`contents[${index}].${key}`}
                          title={t('image')}
                          parentPath={`contents[${index}]`}
                          index={subIndex}
                        ></CollapseContainer>
                      );
                    } else if (typeof value === 'object' && contents != null) {
                      //header, hero, body, footer為key 這些key才會render收合展開
                      return (
                        <CollapseContainer
                          key={`contents[${index}].${key}`}
                          selectedPath={selectedPath}
                          onSelect={handleSelect}
                          content={value}
                          path={`contents[${index}].${key}`}
                          title={
                            key === 'header'
                              ? t('flexEditor.header')
                              : key === 'hero'
                              ? t('flexEditor.hero')
                              : key === 'body'
                              ? t('flexEditor.body')
                              : key === 'footer'
                              ? t('flexEditor.footer')
                              : key
                          }
                          parentPath={`contents[${index}]`}
                          index={subIndex}
                        >
                          {contents.map(
                            contentRenderer({
                              selectedPath,
                              parentPath: `contents[${index}].${key}`,
                              handleSelect,
                            })
                          )}
                        </CollapseContainer>
                      );
                    } else return null;
                  })}
              </CollapseContainer>
            );
          })}
        </CollapseContainer>
      ) : (
        <CollapseContainer
          key="bubble"
          selectedPath=""
          onSelect={() => {
            onSelect({
              path: '',
              parentPath: '',
              index: 0,
              content: json,
            });
          }}
          content={{}}
          path=""
          title={t('flexEditor.bubble')}
          parentPath=""
          index={0}
        >
          {Object.entries(json).map(([key, value], i) => {
            const { contents = [], type } = value;
            if (key === 'styles') {
              return null;
            }
            if (type === 'image') {
              return (
                <CollapseContainer
                  key={key}
                  selectedPath={selectedPath}
                  onSelect={handleSelect}
                  content={value}
                  path={key}
                  title={t('image')}
                  parentPath=""
                  index={i}
                ></CollapseContainer>
              );
            } else if (typeof value === 'object' && contents != null) {
              //header, hero, body, footer為key 這些key才會render收合展開
              return (
                <CollapseContainer
                  key={key}
                  selectedPath={selectedPath}
                  onSelect={handleSelect}
                  content={value}
                  path={key}
                  title={
                    key === 'header'
                      ? t('flexEditor.header')
                      : key === 'hero'
                      ? t('flexEditor.hero')
                      : key === 'body'
                      ? t('flexEditor.body')
                      : key === 'footer'
                      ? t('flexEditor.footer')
                      : key
                  }
                  parentPath=""
                  index={i}
                >
                  {contents.map(
                    contentRenderer({
                      selectedPath,
                      parentPath: key,
                      handleSelect,
                    })
                  )}
                </CollapseContainer>
              );
            } else return null;
          })}
        </CollapseContainer>
      )}
    </TreeContainer>
  );
}

Tree.propTypes = {
  json: PropTypes.object,
  selectedPath: PropTypes.string,
  onSelect: PropTypes.func,
};

export default Tree;
