import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Swapper from './Swapper';
import { fontSizeOptions } from '../../templates/styledComponents';
import TextFieldWithFlexVar from '../../../utilsComponents/TextFieldWithFlexVar';
import { t } from 'i18n/config';

const fontWeightOptions = ['regular', 'bold'];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '70%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const Container = styled.div`
  > div {
    width: 85%;
  }
`;

function TextEditor({
  onSwapUp,
  onSwapDown,
  onChange,
  path,
  content,
  onRemove,
  parentPath,
  index,
  onValidColorChange,
  onTextEmptyStatus,
}) {
  const classes = useStyles();
  const handleChange = (e) => {
    onChange({
      path,
      content: {
        ...content,
        [e.target.name]: e.target.value,
      },
      index,
      parentPath,
    });
  };

  const handleFlexVarChange = (changes) => {
    onChange({
      path,
      content: {
        ...content,
        ...changes,
      },
      index,
      parentPath,
    });
  };
  return (
    <Container>
      <FormControl className={classes.formControl}>
        <Swapper onSwapDown={onSwapDown} onSwapUp={onSwapUp} onRemove={onRemove} />
      </FormControl>
      <TextFieldWithFlexVar
        name="text"
        value={content.text}
        onChange={handleFlexVarChange}
        title={t('text')}
        onTextEmptyStatus={onTextEmptyStatus}
      />
      <TextFieldWithFlexVar
        name="color"
        value={content.color || '#000000'}
        onChange={handleFlexVarChange}
        title={t('color')}
        onValidColorChange={onValidColorChange}
      />
      <FormControl className={classes.formControl}>
        <InputLabel id="size">{t('flexEditor.size')}</InputLabel>
        <Select name="size" labelId="size" id="size-select" value={content.size} onChange={handleChange}>
          {fontSizeOptions.map((op) => (
            <MenuItem key={op} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="weight">{t('flexEditor.weight')}</InputLabel>
        <Select name="weight" labelId="weight" id="weight-select" value={content.weight} onChange={handleChange}>
          {fontWeightOptions.map((op) => (
            <MenuItem key={op} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Container>
  );
}

TextEditor.propTypes = {
  path: PropTypes.string,
  content: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.string,
    align: PropTypes.string,
    weight: PropTypes.string,
    size: PropTypes.string,
    flex: PropTypes.string,
  }),
  parentPath: PropTypes.string,
  index: PropTypes.number,
  onChange: PropTypes.func,
  onSwapUp: PropTypes.func,
  onSwapDown: PropTypes.func,
  onRemove: PropTypes.func,
};

export default TextEditor;
