export const defaultText = {
  size: 'md',
  text: 'hello 你好嗎',
  type: 'text',
  weight: 'regular',
  wrap: true,
};

export const defaultImage = {
  type: 'image',
  url: 'https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png',
};
