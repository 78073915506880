import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ListGroupItem,
  ListGroup,
} from 'reactstrap';
import Spinner from 'react-spinkit';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import connectData from 'components/HOCs/connectData';
import './Response.css';
import { isLogin, timeAgo, getDateFormat } from '../../helpers';
import { fetchReportDataByTime, doneRefreshPage } from '../../reducers/customers';
import { Platform } from '../../utils/appContant';
import ReviewInfoCell from './ReviewInfoCell';
import { currentLanguage, t } from 'i18n/config';
import styled from 'styled-components';

const WordingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  background-color: rgba(52, 157, 208, 1);
  padding-left: 24px;
  padding-right: 24px;

  > p {
    margin: 0;
    font-size: 23px;
    color: white;
    font-weight: 700;
  }
`;

// Social Box Chart
const socialBoxData = [
  { data: [65, 59, 84, 84, 51, 55, 40], label: 'facebook' },
  { data: [1, 13, 9, 17, 34, 41, 38], label: 'twitter' },
  { data: [78, 81, 80, 45, 34, 12, 40], label: 'linkedin' },
  { data: [35, 23, 56, 22, 97, 23, 64], label: 'google' },
];

const makeSocialBoxData = (dataSetNo) => {
  const dataset = socialBoxData[dataSetNo];
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        backgroundColor: 'rgba(255,255,255,.1)',
        borderColor: 'rgba(255,255,255,.55)',
        pointHoverBackgroundColor: '#fff',
        borderWidth: 2,
        data: dataset.data,
        label: dataset.label,
      },
    ],
  };
  return () => data;
};

const socialChartOpts = {
  tooltips: false,
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: false,
      },
    ],
    yAxes: [
      {
        display: false,
      },
    ],
  },
  elements: {
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3,
    },
  },
};

const mapStateToProps = (state) => ({
  isRefresh: state.ui.isRefresh,
  clients: state.clients,
});

const mapDippatchToProps = {
  fetchReportDataByTime,
  doneRefreshPage,
};

export default
@connectData
@connect(mapStateToProps, mapDippatchToProps)
class Response extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    clients: PropTypes.shape({
      byId: PropTypes.object,
      selectedIds: PropTypes.array,
    }),
    fetchReportDataByTime: PropTypes.func,
    doneRefreshPage: PropTypes.func,
    isRefresh: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const today = new Date();
    const y = today.getFullYear();
    const m = today.getMonth();
    const firstDay = new Date(y, m, 2);
    this.state = {
      isloading: true,
      currentSelectedPlatform: Platform.GOOGLE,
      alleypinModel: false,
      isOpenCustomerModal: false,
      customerInfo: '',
      startDate: moment(firstDay.toISOString().slice(0, 10)),
      endDate: moment(new Date()),
      ...this.defaultReivew,
    };
  }
  componentDidMount() {
    if (!isLogin()) {
      this.props.history.push('/login');
    }
    this.onFetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isRefresh === false && prevProps.isRefresh !== this.props.isRefresh) {
      // customer created, update sms number
      this.resetData();
    }
  }

  onClearData = () => {
    this.setState({
      ...this.defaultReivew,
      isloading: true,
    });
  };

  onFetchData = async () => {
    try {
      const fetchingClients = this.props.clients.selectedIds.join('');
      const res = await this.props.fetchReportDataByTime(
        new Date(getDateFormat(this.state.startDate)),
        new Date(getDateFormat(this.state.endDate))
      );
      const currentClients = this.props.clients.selectedIds.join('');
      // clients may be changed in redux during query,
      // hence there's a check to make sure the result is what users want
      // if not, then query again
      if (fetchingClients !== currentClients) {
        this.onFetchData();
        return;
      }
      const { googleReview, fbReview, apReview, apReviewFeedback, tripAdvisorReview } = res;
      const googleReviewWithMark = googleReview.map((review) => ({
        ...review,
        author_name: review.customerName,
        relative_time_description: timeAgo(new Date(review.createdAt).getTime()),
        text: review.reviewText,
        googleReviewId: review.reviewId,
        googleAccountId: review.googleAccountId,
        googleLocationId: review.googleLocationId,
        reply: review.reply,
      }));
      const fbReviewWithMark = fbReview.map((review) => ({
        ...review,
        author_name: review.customerName,
        relative_time_description: timeAgo(new Date(review.createdAt).getTime()),
        text: review.reviewText,
        fbPostId: review.fbPostId,
      }));
      /* eslint no-script-url: 0 */
      const apReviewWithMark = apReview.map((review) => ({
        ...review,
        author_name: review.customerName,
        relative_time_description: timeAgo(new Date(review.createdAt).getTime()),
        text: review.reviewText,
      }));
      const tripAdvisorReviewWithMark = tripAdvisorReview.map((review) => ({
        ...review,
        author_name: review.customerName,
        relative_time_description: timeAgo(new Date(review.createdAt).getTime()),
        text: review.reviewText,
        reply: review.reply,
      }));
      const apReviewFeedbackWithMark = apReviewFeedback.map((review) => ({
        ...review,
        author_name: review.customerName,
        relative_time_description: timeAgo(new Date(review.createdAt).getTime()),
        text: review.feedback,
        locationUrl: 'javascript:void(0);',
        profile_photo_url: 'https://s3-ap-northeast-1.amazonaws.com/allypin-assets/avatarBlank.png',
        viaImageUrl: 'https://s3-ap-northeast-1.amazonaws.com/allypin-assets/via_alleypin.png',
      }));

      let selectedPlatform = this.state.currentSelectedPlatform;
      switch (true) {
        case googleReviewWithMark.length > 0:
          selectedPlatform = Platform.GOOGLE;
          break;
        case fbReviewWithMark.length > 0:
          selectedPlatform = Platform.FACEBOOK;
          break;
        case tripAdvisorReviewWithMark.length > 0:
          selectedPlatform = Platform.TRIPADVISOR;
          break;
        case apReviewWithMark.length > 0:
          selectedPlatform = Platform.ALLEYPIN;
          break;
        case apReviewFeedbackWithMark.length > 0:
          selectedPlatform = Platform.ALLEYPIN_FEEDBACK;
          break;
        default:
          break;
      }

      this.setState({
        currentSelectedPlatform: selectedPlatform,
        reviews: {
          google: {
            rating: this.getAverageRating(googleReviewWithMark),
            reviewsNumber: googleReviewWithMark.length,
            reviews: googleReviewWithMark,
          },
          fb: {
            rating: this.getAverageRating(fbReviewWithMark),
            reviewsNumber: fbReviewWithMark.length,
            reviews: fbReviewWithMark,
          },
          alleypin: {
            rating: this.getAverageRating(apReviewWithMark),
            reviewsNumber: apReviewWithMark.length,
            reviews: apReviewWithMark,
          },
          alleypinFeedback: {
            rating: apReviewFeedbackWithMark.length,
            reviewsNumber: apReviewFeedbackWithMark.length,
            reviews: apReviewFeedbackWithMark,
          },
          tripAdvisor: {
            rating: this.getAverageRating(tripAdvisorReviewWithMark),
            reviewsNumber: tripAdvisorReviewWithMark.length,
            reviews: tripAdvisorReviewWithMark,
          },
        },
        isloading: false,
      });

      this.props.doneRefreshPage();
    } catch (error) {
      const res = await error;
      console.log('fail to load report data', res);
    }
  };

  onClickBox = (platform) => () => {
    this.setState({
      currentSelectedPlatform: platform,
    });
  };

  onClickShareOnFb = (shareLink) => {
    window.open(shareLink);
  };

  onClickReviewRangeButton = () => {
    this.resetData();
  };

  getAverageRating = (reviews) => {
    if (reviews.length === 0) {
      return 0;
    }
    return parseFloat(
      reviews
        .map((review) => (!Number.isNaN(Number(review.rating)) ? Number(review.rating) : 0))
        .reduce((acc, rating) => acc + rating, 0) / reviews.length
    ).toFixed(1);
  };

  resetData = () => {
    this.onClearData();
    this.onFetchData();
  };

  defaultPlatform = {
    [Platform.GOOGLE]: true,
    [Platform.FACEBOOK]: true,
    [Platform.ALLEYPIN_FEEDBACK]: true,
  };

  defaultReivew = {
    reviews: {
      google: {
        rating: 0,
        reviewsNumber: 0,
        reviews: [],
      },
      fb: {
        rating: 0,
        reviewsNumber: 0,
        reviews: [],
      },
      tripAdvisor: {
        rating: 0,
        reviewsNumber: 0,
        reviews: [],
      },
      alleypin: {
        rating: 0,
        reviewsNumber: 0,
        reviews: [],
      },
      alleypinFeedback: {
        rating: 0,
        reviewsNumber: 0,
        reviews: [],
      },
    },
  };

  toggleAlleypinModal = (info) => {
    this.setState({ alleypinModel: !this.state.alleypinModel, customerInfo: info });
  };

  toggleCustomerModal = (info) => {
    this.setState({ isOpenCustomerModal: !this.state.isOpenCustomerModal, customerInfo: info });
  };

  renderItem = (platform) =>
    this.state.reviews[platform].reviews.map((info) => (
      <ReviewInfoCell
        key={`${info.author_name}-${info.rating}`}
        info={info}
        onClickShareOnFb={this.onClickShareOnFb}
        toggleAlleypinModal={this.toggleAlleypinModal}
        toggleCustomerModal={this.toggleCustomerModal}
        currentSelectedPlatform={this.state.currentSelectedPlatform}
      />
    ));

  renderBox = ({ platform, rating, reviewsNumber }) => {
    let boxClass;
    let fontClass;
    let style;
    switch (platform) {
      case Platform.GOOGLE:
        boxClass = 'google';
        fontClass = 'fa fa-google';
        break;
      case Platform.FACEBOOK:
        boxClass = 'facebook';
        fontClass = 'fa fa-facebook';
        break;
      case Platform.TRIPADVISOR:
        boxClass = 'tripadvisor';
        fontClass = 'fa fa-tripadvisor';
        break;
      case Platform.ALLEYPIN:
        boxClass = 'alleypin';
        fontClass = '';
        break;
      case Platform.ALLEYPIN_FEEDBACK:
        boxClass = 'alleypinFeedback';
        if (currentLanguage === 'jp') {
          style = { fontSize: '23px', lineHeight: '30px' };
        }
        fontClass = '';
        break;
      default:
        boxClass = 'google';
        fontClass = 'fa fa-google';
        break;
    }

    const renderIcon = () => {
      if (platform === Platform.ALLEYPIN_FEEDBACK) {
        return (
          <WordingWrapper>
            <p>{t('alleypinFeedback')}</p>
          </WordingWrapper>
        );
      }
      return <i className={fontClass} />;
    };

    return (
      <Col xs="6" sm="6" lg="3" onClick={this.onClickBox(platform)} role="presentation">
        <div className={`social-box ${boxClass}`}>
          {renderIcon()}
          <div className="chart-wrapper">
            <Line data={makeSocialBoxData(3)} options={socialChartOpts} height={90} />
          </div>
          <ul>
            <li>
              <strong>{rating}</strong>
              <span>{t('average')}</span>
            </li>
            <li>
              <strong>{reviewsNumber}</strong>
              <span>{t('numberOfReviews')}</span>
            </li>
          </ul>
        </div>
      </Col>
    );
  };

  renderSummaryBox = () =>
    Object.keys(this.state.reviews)
      .filter((platform) => this.state.reviews[platform].reviews.length > 0 || this.defaultPlatform[platform])
      .map((platform) =>
        this.renderBox({
          platform,
          ...this.state.reviews[platform],
        })
      );

  render() {
    /* eslint object-curly-newline: 0 */
    return (
      <div className="animated fadeIn">
        <Row className="mt-4">
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col sm="5">
                    <DateRangePicker
                      isOutsideRange={(day) => day.isAfter(moment())}
                      displayFormat="YYYY-MM-DD"
                      startDateId="startDateId"
                      startDate={this.state.startDate}
                      endDateId="endDateId"
                      endDate={this.state.endDate}
                      onDatesChange={({ startDate, endDate }) => {
                        this.setState({
                          startDate,
                          endDate,
                        });
                      }}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) => {
                        this.setState({ focusedInput });
                      }}
                    />
                  </Col>
                  <Col>
                    <Button color="secondary" className="float-right" onClick={this.onClickReviewRangeButton}>
                      {t('updateInterval')}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>{this.renderSummaryBox()}</Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>{t('recentReviews')}</CardHeader>
              <CardBody style={{ padding: '35px' }}>
                <Row>
                  <Col sm="12">
                    {this.state.isloading && <Spinner name="circle" />}
                    <ul
                      key={this.state.currentSelectedPlatform}
                      hidden={this.state.isloading}
                      className="list-group review-section"
                    >
                      {this.renderItem(this.state.currentSelectedPlatform)}
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={this.state.isOpenCustomerModal} toggle={this.toggleCustomerModal}>
          <ModalHeader toggle={this.toggleCustomerModal}>
            {t('customerInformation')} {this.state.customerInfo.name}
          </ModalHeader>
          <ModalBody>
            <ListGroup>
              <ListGroupItem tag="a" href={`mailto:${this.state.customerInfo.email}`}>
                {this.state.customerInfo.email === 'xxx' ? '' : this.state.customerInfo.email}
              </ListGroupItem>
              <ListGroupItem tag="a" href={`tel:+886${this.state.customerInfo.phone}`}>
                {this.state.customerInfo.phone === '00000' ? '' : this.state.customerInfo.phone}
              </ListGroupItem>
              <ListGroupItem>{this.state.customerInfo.followUp || ''}</ListGroupItem>
            </ListGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleCustomerModal}>
              {t('confirm')}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.alleypinModel} toggle={this.toggleAlleypinModal}>
          <ModalHeader toggle={this.toggleAlleypinModal}>
            {t('customerInformation')} {this.state.customerInfo.author_name}
          </ModalHeader>
          <ModalBody>
            <ListGroup>
              <ListGroupItem tag="a" href={`mailto:${this.state.customerInfo.email}`}>
                {this.state.customerInfo.email}
              </ListGroupItem>
              <ListGroupItem tag="a" href={`tel:+886${this.state.customerInfo.phone}`}>
                {this.state.customerInfo.phone}
              </ListGroupItem>
              <ListGroupItem>{this.state.customerInfo.followUp || ''}</ListGroupItem>
            </ListGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleAlleypinModal}>
              {t('confirm')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
