import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import connectData from 'components/HOCs/connectData';
import Page from './Page';

const propTypes = {
  clients: PropTypes.shape({
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    byId: PropTypes.object,
  }),
};

const defaultProps = {};

@connectData
class FlexEditor extends PureComponent {
  isMultipleSelectedStores = () => this.props.clients.selectedIds.length > 1;
  render() {
    const { clients } = this.props;
    return (
      <React.Fragment>
        {clients.selectedIds.map((id) => {
          const { id: clientId, vendor: vendor } = clients.byId[id];
          return <Page key={clientId} clientId={clientId} vendor={vendor}></Page>;
        })}
      </React.Fragment>
    );
  }
}

FlexEditor.propTypes = propTypes;
FlexEditor.defaultProps = defaultProps;

export default FlexEditor;
