import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import Menu from '@material-ui/core/Menu';
import { defaultText, defaultImage } from './defaultContent';
import { t } from 'i18n/config';

const Container = styled.div``;

function Swapper({ onSwapUp, onSwapDown, onRemove, onAdd, enableAdd }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (newContent) => () => {
    onAdd(newContent)();
    handleClose();
  };

  return (
    <Container>
      {onRemove && <DeleteForeverIcon onClick={onRemove} />}
      {onSwapUp && <KeyboardArrowUpIcon onClick={onSwapUp} />}
      {onSwapDown && <KeyboardArrowDownIcon onClick={onSwapDown} />}
      {enableAdd && (
        <React.Fragment>
          <AddIcon onClick={handleClick} />
          <Menu id="add-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleMenuClick(defaultText)}>{t('text')}</MenuItem>
            <MenuItem onClick={handleMenuClick(defaultImage)}>{t('image')}</MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </Container>
  );
}

Swapper.propTypes = {
  onSwapDown: PropTypes.func,
  onSwapUp: PropTypes.func,
  onRemove: PropTypes.func,
  onAdd: PropTypes.func,
  enableAdd: PropTypes.bool,
};

export default Swapper;
