import React, { useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dialog from 'components/Dashboard_v2/Dialog';
import Button from 'components/Dashboard_v2/Button';
import Text from 'components/Dashboard_v2/Text';
import Input from 'components/Dashboard_v2/Input';
import { dashboard_v2 } from 'theme';
import { generateContent } from 'lib/openaiService';

import { isValidURL, getMessageData, getDefaultReplyContentData } from '../../utils';
import { FlowIcon } from '../EditBlocks';
import ImageEditor from './ImageEditor';
import TextEditor from './TextEditor';
import MessageBubble from './MessageBubble';
import FlexEditor from './FlexEditor';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const IconButton = styled(Button)`
  margin-left: 16px;
  white-space: nowrap;
  i {
    margin-right: 8px;
  }
`;

const ModalTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: ${colors.SHADES_800};
  margin-bottom: 24px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EditorWrapper = styled.div`
  padding-right: 16px;
  margin-top: 24px;
  width: 545px;
  max-height: 60vh;
  overflow: auto;
`;

const PreviewWrapper = styled.div`
  padding-right: 16px;
  margin-top: 24px;
  width: 500px;
  max-height: 60vh;
  overflow: auto;
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  min-height: 400px;
  background: ${colors.SHADES_100};
  border: 1px solid ${colors.SHADES_300};
  border-radius: 8px;
`;

const Block = styled.div`
  padding: 24px;
  margin-top: 24px;
  border: 1px solid ${colors.SHADES_300};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  ${({ center }) =>
    center &&
    `
    align-items: center;
    justify-content: center;
  `}
  > button {
    margin: 0;
  }
`;

const MESSAGE_TYPES = [
  {
    type: 'text',
    label: t('text'),
    icon: 'ri-file-text-line',
  },
  {
    type: 'flex',
    label: t('interactiveMessage'),
    icon: 'ri-layout-bottom-2-line',
  },
  {
    type: 'image',
    label: t('image'),
    icon: 'ri-image-fill',
  },
];

const MessageModal = ({
  data = {
    alias: '',
    message: [],
    replies: [],
    currentAlias: [],
  },
  clientId,
  onConfirm,
  onClose,
  viewMode,
  hideAlias = false,
  openAIEnabled,
}) => {
  const MESSAGE_LIMIT = 5;
  const [alias, setAlias] = useState(data.alias || '');
  const [replies, setReplies] = useState(data.replies);
  const [messages, setMessages] = useState(data.message);

  const hasDuplicateAlias = useMemo(
    () => data.currentAlias && data.currentAlias.includes(alias),
    [data.currentAlias, alias]
  );

  const isDisabled = useMemo(() => {
    const isEmptyAlias = !hideAlias && !alias;
    const isEmptyMessageList = !messages.length;
    const hasEmptyContent = messages.some((message, index) => {
      const isEmptyTextContent = message.type === 'text' && !message.textContent;
      const isEmptyImageContent = message.type === 'image' && !message.previewImageUrl;
      const isEmptyFlexContent =
        message.type === 'flex' && (!message.alertText || !message.textTitle || !message.textContent);
      const isEmptyReplyButtonText =
        message.showReplyButtons && message.replyButtons?.some(({ buttonText }) => !buttonText);
      const isEmptyReplyContent =
        message.showReplyButtons &&
        (!message.replyButtons?.length ||
          message.replyButtons?.length !== replies[index]?.length ||
          replies[index]?.some(({ replyType, replyContent }) => replyType && !replyContent));
      const hasInvalidUrl =
        message.showReplyButtons &&
        replies[index]?.some(({ replyType, replyContent }) => replyType === 'link' && !isValidURL(replyContent));
      return (
        viewMode ||
        isEmptyTextContent ||
        isEmptyImageContent ||
        isEmptyFlexContent ||
        isEmptyReplyButtonText ||
        isEmptyReplyContent ||
        hasInvalidUrl
      );
    });

    return hasDuplicateAlias || isEmptyAlias || isEmptyMessageList || hasEmptyContent;
  }, [viewMode, alias, replies, messages, hasDuplicateAlias, hideAlias]);

  const handleChange = (key, value, index) => {
    if (key === 'replyContents') {
      const newReplies = [...replies];
      newReplies[index] = value;
      setReplies(() => newReplies);
    } else {
      const newMessages = [...messages];
      newMessages[index][key] = value;
      setMessages(() => newMessages);
    }
  };

  const handleImageChange = (imageUrl, index) => {
    const newMessages = [...messages];
    newMessages[index] = {
      ...newMessages[index],
      originalContentUrl: imageUrl,
      previewImageUrl: imageUrl,
    };
    setMessages(() => newMessages);
  };

  const handleMessageAdd = (type) => {
    const data = getMessageData(type);
    setMessages((prev) => [...prev, data]);
    setReplies((prev) => [...prev, type === 'flex' ? [getDefaultReplyContentData()] : null]);
  };

  const handleMessageMove = (position, index) => {
    const newReplies = [...replies];
    const newMessages = [...messages];
    if (position === 'up') {
      [newReplies[index - 1], newReplies[index]] = [newReplies[index], newReplies[index - 1]];
      [newMessages[index - 1], newMessages[index]] = [newMessages[index], newMessages[index - 1]];
    } else if (position === 'down') {
      [newReplies[index + 1], newReplies[index]] = [newReplies[index], newReplies[index + 1]];
      [newMessages[index + 1], newMessages[index]] = [newMessages[index], newMessages[index + 1]];
    }
    setReplies(() => newReplies);
    setMessages(() => newMessages);
  };

  const handleMessageDelete = (index) => {
    const newReplies = [...replies];
    const newMessages = [...messages];
    newReplies.splice(index, 1);
    newMessages.splice(index, 1);
    setReplies(() => newReplies);
    setMessages(() => newMessages);
  };

  const handleConfirm = () => {
    onConfirm({ alias, message: messages, replies });
    onClose();
  };

  const handleOpenAI = async ({ role, content, args, callback }) => {
    await generateContent({
      clientID: clientId,
      role,
      content,
      args,
      callback: callback,
    });
  };

  return (
    <Dialog
      open={true}
      width={1140}
      zIndex={1100}
      title={<FlowIcon type="message" />}
      description={
        <div>
          <ModalTitle>{t('sendMessage')}</ModalTitle>
          <Container>
            <EditorWrapper>
              <Text font="Heading/H6/Medium" color="SHADES_800" marginBottom={8}>
                {t('message')}
              </Text>
              <Text font="Body/14px_Regular" color="SHADES_400" block>
                {t('maxMessageCount', { n: MESSAGE_LIMIT })}
              </Text>
              {/* Title Block */}
              {!hideAlias && (
                <Block>
                  <Text font="Heading/Large/Medium" marginBottom={36}>
                    {t('title')}
                  </Text>
                  <Input
                    label={t('messageTitle')}
                    value={alias}
                    onInputChange={(e) => setAlias(e.target.value)}
                    helperText={hasDuplicateAlias ? t('duplicateMessageTitle') : t('messageTitleDescription')}
                    error={hasDuplicateAlias}
                    marginBottom={24}
                    fullWidth
                    disabled={viewMode}
                  />
                </Block>
              )}
              {/* Messages Editors */}
              {messages.map((message, index) => {
                const commonProps = {
                  data: { ...message, replyContents: replies[index] },
                  clientId,
                  handleMessageMove: (position) => handleMessageMove(position, index),
                  handleMessageDelete: () => handleMessageDelete(index),
                  allowMoveUp: index !== 0,
                  allowMoveDown: index !== messages.length - 1,
                  disabled: viewMode,
                };
                return (
                  <React.Fragment key={index}>
                    {message.type === 'text' && (
                      <TextEditor
                        handleChange={(key, value) => handleChange(key, value, index)}
                        {...commonProps}
                        handleOpenAI={openAIEnabled ? handleOpenAI : undefined}
                      />
                    )}
                    {message.type === 'flex' && (
                      <FlexEditor
                        handleChange={(key, value) => handleChange(key, value, index)}
                        handleOpenAI={openAIEnabled ? handleOpenAI : undefined}
                        {...commonProps}
                      />
                    )}
                    {message.type === 'image' && (
                      <ImageEditor
                        handleChange={(imageUrl) => handleImageChange(imageUrl, index)}
                        handleOpenAI={openAIEnabled ? handleOpenAI : undefined}
                        {...commonProps}
                      />
                    )}
                  </React.Fragment>
                );
              })}
              {/* Add Edit Message */}
              <Block hidden={messages.length === MESSAGE_LIMIT}>
                <Text font="Heading/Large/Medium" color="SHADES_800" marginBottom={32}>
                  {t('selectMessageType')}
                </Text>
                <ActionButtons>
                  {MESSAGE_TYPES.map(({ type, label }) => (
                    <IconButton
                      variant="outline"
                      color="secondary"
                      fontSize={19}
                      onClick={() => handleMessageAdd(type)}
                      disabled={viewMode}
                      key={type}
                    >
                      <i className="ri-file-text-line" />
                      {label}
                    </IconButton>
                  ))}
                </ActionButtons>
                <Text font="Body/14px_Regular" color="SHADES_400" marginTop={32}>
                  {t('sendMessageCount')} {MESSAGE_LIMIT - messages.length} {t('messages')}
                </Text>
              </Block>
            </EditorWrapper>
            <PreviewWrapper>
              <Text font="Heading/H6/Medium" color="SHADES_800" marginBottom={16}>
                {t('previewMessage')}
              </Text>
              <PreviewContainer>
                {messages.map((message, index) => (
                  <MessageBubble data={{ ...message, replyContents: replies[index] }} key={index} />
                ))}
              </PreviewContainer>
            </PreviewWrapper>
          </Container>
        </div>
      }
      confirmText={t('save')}
      cancelText={t('cancel')}
      onConfirm={handleConfirm}
      onCancel={onClose}
      confirmColorType="primary"
      disabled={isDisabled}
      viewMode={viewMode}
    />
  );
};

const propTypes = {
  data: PropTypes.shape({
    alias: PropTypes.string,
    message: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          type: PropTypes.string,
          textContent: PropTypes.string,
          confirmText: PropTypes.string,
          cancelText: PropTypes.string,
          isFlexMessage: PropTypes.bool,
        }),
        PropTypes.shape({
          type: PropTypes.string,
          originalContentUrl: PropTypes.string,
          previewImageUrl: PropTypes.string,
        }),
      ])
    ),
  }),
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  viewMode: PropTypes.bool,
  hideAlias: PropTypes.bool,
  openAIEnabled: PropTypes.bool,
};

MessageModal.propTypes = propTypes;

export default memo(MessageModal);
