import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { get } from 'lodash-es';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import {
  getTemplate,
  getPatientTracking,
  createPatientTracking,
  updatePatientTracking,
  enablePatientTracking,
  disablePatientTracking,
  patchPatientTracking,
} from 'lib/messageCenterService';
import connectData from 'components/HOCs/connectData';
import useAlert from 'hooks/useAlert';

import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Block from 'components/Dashboard_v2/Block';
import Dialog from 'components/Dashboard_v2/Dialog';
import Switch from 'components/Dashboard_v2/Switch';
import Text from 'components/Dashboard_v2/Text';
import Alert from 'components/Dashboard_v2/Alert';

import { InfoBlock, ActionBlock } from '../components/EditBlocks';
import TitleModal from '../components/TitleModal';
import MessageModal from '../components/MessageModal';
import DateRangeModal from '../components/DateRangeModal';
import TimeModal, { getDisplayTime } from '../components/TimeModal';
import { conventFormDataToTemplateData, conventTemplateDataToFormData } from '../utils';
import TemplateListModal from '../components/TemplateListModal';
import RunAtModal from 'feature/MessageCenter/components/RunAtModal';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Container = styled.div`
  position: relative;
  padding-bottom: 150px;
`;

const Header = styled.div`
  padding: 30px 30px 24px;
  margin: 0 -30px;
  background-color: ${colors.SHADES_000};
  border-bottom: 1px solid ${colors.SHADES_200};
  display: flex;
`;

const TimeBlockTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const FlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormActions = styled.div`
  margin-left: auto;
  display: flex;
  gap: 0 8px;
`;

const Main = styled.div`
  margin: 30px 0;
`;

const TemplateBlockTitle = styled.div`
  display: flex;
  align-items: center;
  i {
    font-size: 30px;
    color: ${colors.SHADES_400};
    margin-right: 24px;
  }
`;

const TemplateBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 32px 24px;
  border: solid 1px ${colors.SHADES_400};
  border-radius: 8px;
  background-color: ${colors.SHADES_000};
  font-size: 16px;
  font-weight: 700;
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;

  i {
    font-size: 14px;
  }
`;

const MODE_NAME_MAP = {
  create: t('add'),
  edit: t('edit'),
  enabled: t('enable'),
  disabled: t('disable'),
  update: t('update'),
};

const getDefaultAction = () => ({
  alias: null,
  timeUnit: null,
  timeValue: null,
  hour: null,
  minute: null,
  delaySeconds: null,
  message: [],
  replies: [],
});

const getInitialFormState = (clientId) => ({
  title: '',
  status: 'draft',
  recyclable: false,
  clientId,
  startTime: null,
  endTime: null,
  messageTemplateId: null,
  messageTemplateTitle: null,
  templateFlow: {
    actions: [getDefaultAction()],
  },
});

const getDisplayDateRange = (startTime, endTime) => {
  if (!startTime || !endTime) return null;
  return `${moment(startTime).format('YYYY/MM/DD HH:mm')} - ${moment(endTime).format('YYYY/MM/DD HH:mm')}`;
};

const PatientTrackingForm = ({ mode, match }) => {
  const patientTrackingId = match.params.id;
  const { renderAlert, setAlert } = useAlert();
  const clientName = useSelector((state) => state.auth.user.companyName);
  const [clientId] = useSelector((state) => state.clients.selectedIds);
  const { openAI: openAIEnabled } = useSelector((state) => state.clients.byId[clientId]?.features);
  const [formData, setFormData] = useState(null);
  const [redirectToList, setRedirectToList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataIndex, setDataIndex] = useState(null);
  const [editModal, setEditModal] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const disableEdit = formData && formData.status !== 'draft';

  const disabledSubmit = useMemo(() => {
    if (!formData) {
      return true;
    }

    const { title, templateFlow, startTime, endTime, recyclable } = formData;
    const { actions } = templateFlow;
    const isEmptyTitle = !title;
    const isEmptyDateRange = !startTime || !endTime;
    const isEmptyActions = !actions.length;
    const hasEmptyActionContent = actions.some((action) => {
      const { alias, delaySeconds, message, timeUnit } = action;
      const isEmptyAlias = !alias;
      const isEmptyTime = recyclable && !delaySeconds;
      const isEmptyMessage = !message.length;
      return isEmptyAlias || isEmptyTime || isEmptyMessage || !timeUnit;
    });
    return isEmptyTitle || isEmptyDateRange || isEmptyActions || hasEmptyActionContent;
  }, [formData]);

  const handleModalOpen = (type, index) => {
    setDataIndex(index);
    setEditModal(type);
  };

  const handleModalClose = () => {
    setDataIndex(null);
    setEditModal(null);
  };

  const handleActionAdd = (index, action) => {
    const position = index === null ? 0 : index + 1;
    const actions = [...formData.templateFlow.actions];
    const newActions = [...actions.slice(0, position), action || getDefaultAction(), ...actions.slice(position)];
    setFormData((prev) => ({
      ...prev,
      templateFlow: {
        ...prev.templateFlow,
        actions: newActions,
      },
    }));
  };

  const handleActionClone = (index) => {
    const cloneAction = JSON.parse(JSON.stringify(formData.templateFlow.actions[index]));
    cloneAction.alias = `${cloneAction.alias} ${t('clone')}`;
    delete cloneAction.id;
    delete cloneAction.clientId;
    delete cloneAction.parentId;
    handleActionAdd(index, cloneAction);
  };

  const handleActionDelete = (index) => {
    const actions = [...formData.templateFlow.actions];
    actions.splice(index, 1);
    setFormData((prev) => ({
      ...prev,
      templateFlow: {
        ...prev.templateFlow,
        actions,
      },
    }));
  };

  const handleTemplateChange = useCallback(
    async (templateId) => {
      if (templateId === 'empty') {
        setFormData(() => ({
          ...getInitialFormState(clientId),
          messageTemplateId: null,
          messageTemplateTitle: t('blankTemplate'),
        }));
        handleModalClose();
      } else {
        try {
          const data = await getTemplate({ clientId, templateId });
          const formattedTemplateData = conventTemplateDataToFormData({ templateType: 'patientTracking', data });
          const templateData = {
            messageTemplateId: formattedTemplateData.id,
            messageTemplateTitle: formattedTemplateData.title,
            templateFlow: formattedTemplateData.templateFlow,
          };
          setFormData((prev) => ({
            ...prev,
            ...templateData,
          }));
          handleModalClose();
        } catch (e) {
          const errRes = await e;
          const { error } = errRes;
          setAlert({ type: 'error', title: t('loadTemplateFailed', { error: error || error.message }) });
          console.error(errRes);
        }
      }
    },
    [clientId, setAlert]
  );

  const handleFormDataChange = useCallback((data) => setFormData((prev) => ({ ...prev, ...data })), []);

  const handleMessageChange = useCallback(
    (messageData) =>
      setFormData((prev) => {
        const newActions = [...prev.templateFlow.actions];
        newActions[dataIndex] = { ...newActions[dataIndex], ...messageData };
        return {
          ...prev,
          templateFlow: {
            ...prev.templateFlow,
            actions: newActions,
          },
        };
      }),
    [dataIndex]
  );

  const handleTimeChange = useCallback(
    (timeData) =>
      setFormData((prev) => {
        const newActions = [...prev.templateFlow.actions];
        newActions[dataIndex] = { ...newActions[dataIndex], ...timeData };
        return {
          ...prev,
          templateFlow: {
            ...prev.templateFlow,
            actions: newActions,
          },
        };
      }),
    [dataIndex]
  );

  const handleSubmit = async ({ updateStatus }) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const payload = conventFormDataToTemplateData({ templateType: 'patientTracking', formData, mode });
      if (mode === 'create') {
        const { id } = await createPatientTracking({ clientId, payload });
        if (updateStatus === 'enabled') {
          await enablePatientTracking({ clientId, patientTrackingId: id });
        }
      }
      if (mode === 'edit') {
        if (updateStatus === 'enabled') {
          await enablePatientTracking({ clientId, patientTrackingId });
        } else if (updateStatus === 'disabled') {
          await disablePatientTracking({ clientId, patientTrackingId });
        } else if (updateStatus === 'update') {
          await patchPatientTracking({
            clientId,
            patientTrackingId,
            payload: {
              runAtSelectable: payload.runAtSelectable,
              templateFlow: payload.templateFlow,
            },
          });
        } else {
          await updatePatientTracking({ clientId, patientTrackingId, payload });
        }
      }
      setAlert({ type: 'success', title: t('successAction', { title: MODE_NAME_MAP[mode].title }) });
      setRedirectToList(true);
    } catch (e) {
      const errRes = await e;
      const { error } = errRes;
      setAlert({ type: 'error', title: error || t('failAction', { type: MODE_NAME_MAP[updateStatus || mode] }) });
      console.error(errRes);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (mode === 'create') {
      setFormData(getInitialFormState());
    }

    if (mode === 'edit') {
      const getPatientTrackingData = async () => {
        try {
          const data = await getPatientTracking({ clientId, patientTrackingId });
          const conventedData = conventTemplateDataToFormData({ templateType: 'patientTracking', data });
          if (!conventedData.messageTemplateTitle) {
            conventedData.messageTemplateTitle = t('blankTemplate');
          }
          setFormData(conventedData);
        } catch (e) {
          const errRes = await e;
          const { error } = errRes;
          setAlert({ type: 'error', title: t('loadDataFailed', { error: error || error.message }) });
          console.error(errRes);
        }
      };
      getPatientTrackingData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return redirectToList ? (
    <Redirect push to="/message_center/patient_tracking" />
  ) : (
    <Container>
      {renderAlert()}
      <Header>
        <Link to="/message_center/patient_tracking">
          <IconButton color="secondary" variant="outline">
            <i className="ri-arrow-left-line" />
            {t('back')}
          </IconButton>
        </Link>
        <FormActions>
          {formData && formData.status === 'draft' && (
            <Button color="secondary" variant="outline" onClick={handleSubmit} disabled={disabledSubmit}>
              {isLoading ? <CircularProgress style={{ color: colors.PRIMARY_400 }} size={20} /> : t('saveDraft')}
            </Button>
          )}
          <Button
            color={formData && ['enabled', 'disabled'].includes(formData.status) ? 'secondary' : 'primary'}
            variant={formData && ['enabled', 'disabled'].includes(formData.status) ? 'outline' : 'filled'}
            onClick={() => setConfirmDialogOpen(true)}
            disabled={disabledSubmit}
          >
            {isLoading ? (
              <CircularProgress style={{ color: colors.SHADES_000 }} size={20} />
            ) : formData && ['draft', 'disabled'].includes(formData.status) ? (
              MODE_NAME_MAP['enabled']
            ) : (
              MODE_NAME_MAP['disabled']
            )}
          </Button>
          {formData && ['enabled', 'disabled'].includes(formData.status) && (
            <Button
              color="primary"
              variant="filled"
              onClick={() => {
                handleSubmit({ updateStatus: 'update' });
              }}
              disabled={disabledSubmit}
            >
              {isLoading ? (
                <CircularProgress style={{ color: colors.SHADES_400 }} size={20} />
              ) : (
                MODE_NAME_MAP['update']
              )}
            </Button>
          )}
        </FormActions>
      </Header>
      {formData && (
        <Main>
          {mode === 'create' && !formData.messageTemplateTitle && (
            <Block
              title={
                <TemplateBlockTitle>
                  <i className="ri-archive-fill" />
                  {t('patientTracking')}
                </TemplateBlockTitle>
              }
            >
              <TemplateBlock>
                {t('patientTrackingTemplate')}
                <Button
                  variant="outline"
                  color="secondary"
                  onClick={() => handleModalOpen('template')}
                  disabled={disableEdit}
                >
                  {t('selectTemplate')}
                </Button>
              </TemplateBlock>
            </Block>
          )}
          {formData.messageTemplateTitle && (
            <React.Fragment>
              {/* Base Info */}
              <Block title={t('basicSetting')}>
                <InfoBlock
                  contents={[{ title: t('itemName'), value: formData.title, placeholder: t('nameExample') }]}
                  onEditButtonClick={() => handleModalOpen('title')}
                  disabled={disableEdit}
                />
                <InfoBlock
                  contents={[
                    {
                      title: t('messageTemplate'),
                      value: formData.messageTemplateTitle || t('blankTemplate'),
                      placeholder: t('messageTemplateExample'),
                    },
                  ]}
                  onEditButtonClick={() => handleModalOpen('template')}
                  disabled={disableEdit}
                />
                <InfoBlock
                  contents={[
                    {
                      title: t('trackingTime'),
                      value: formData.runAtSelectable ? t('specifiedDate') : t('current'),
                    },
                  ]}
                  additionalElement={
                    formData?.tagsWithPatientTrackings?.length > 0 && (
                      <Alert
                        title={t('trackingTag.trackingWarningTitle')}
                        margin={'24px 0 0 0'}
                        description={t('trackingTag.trackingWarningDesc')}
                        type="warning"
                      />
                    )
                  }
                  onEditButtonClick={() => handleModalOpen('runAt')}
                  disabled={formData?.tagsWithPatientTrackings?.length}
                />
              </Block>
              <Block
                title={
                  <TimeBlockTitle>
                    <div>{t('timeAndDateSetting')}</div>
                    <div>
                      <Text font="Body/16px_Heavy">{t('recurringActivity')}</Text>
                      <Switch
                        checked={formData.recyclable}
                        onChange={(e) => handleFormDataChange({ recyclable: e.target.checked })}
                        disabled={disableEdit}
                      />
                    </div>
                  </TimeBlockTitle>
                }
              >
                <InfoBlock
                  contents={[
                    {
                      title: t('activityStartDateToEnd'),
                      value: getDisplayDateRange(formData.startTime, formData.endTime),
                      placeholder: t('selectStartAndEndDate'),
                    },
                  ]}
                  onEditButtonClick={() => handleModalOpen('dateRange')}
                  disabled={disableEdit}
                />
              </Block>
              {/* Flow */}
              <Block title={t('sendingProcessOverview')}>
                <FlowContainer>
                  {formData.templateFlow.actions.map((action, index) => (
                    <ActionBlock
                      time={getDisplayTime(action)}
                      alias={action.alias}
                      eventInfo={
                        index === formData.templateFlow.actions.length - 1
                          ? {
                              range: getDisplayDateRange(formData.startTime, formData.endTime),
                              recyclable: formData.recyclable,
                            }
                          : null
                      }
                      onAddButtonClick={() => handleActionAdd(index)}
                      onCloneButtonClick={() => handleActionClone(index)}
                      onDeleteButtonClick={() => handleActionDelete(index)}
                      onEditTimeClick={() => handleModalOpen('time', index)}
                      onEditMessageClick={() => handleModalOpen('message', index)}
                      hideDelete={index === 0}
                      disabled={disableEdit}
                      key={index}
                    />
                  ))}
                </FlowContainer>
              </Block>
            </React.Fragment>
          )}
        </Main>
      )}
      {/* Edit Modals */}
      {editModal === 'template' && (
        <TemplateListModal
          templateType="patientTracking"
          selectedId={formData.messageTemplateId}
          onSelected={(id) => handleTemplateChange(id)}
          onClose={handleModalClose}
        />
      )}
      {editModal === 'title' && (
        <TitleModal
          data={formData.title}
          onConfirm={(title) => handleFormDataChange({ title })}
          onClose={handleModalClose}
        />
      )}
      {editModal === 'dateRange' && (
        <DateRangeModal
          data={{ startTime: formData.startTime, endTime: formData.endTime, recyclable: formData.recyclable }}
          onConfirm={(date) => handleFormDataChange(date)}
          onClose={handleModalClose}
        />
      )}
      {editModal === 'time' && (
        <TimeModal
          data={formData.templateFlow.actions[dataIndex]}
          recyclable={formData.recyclable}
          onConfirm={handleTimeChange}
          onClose={handleModalClose}
        />
      )}
      {editModal === 'message' && (
        <MessageModal
          data={{
            ...formData.templateFlow.actions[dataIndex],
            currentAlias: formData.templateFlow.actions.map((action, index) =>
              index === dataIndex ? null : action.alias
            ),
          }}
          openAIEnabled={openAIEnabled}
          clientName={clientName}
          clientId={clientId}
          onConfirm={handleMessageChange}
          onClose={handleModalClose}
          viewMode={disableEdit}
        />
      )}
      {editModal === 'runAt' && (
        <RunAtModal
          runAtSelectable={formData.runAtSelectable}
          onConfirm={(runAtSelectable) => handleFormDataChange({ runAtSelectable })}
          onClose={handleModalClose}
        />
      )}
      <Dialog
        open={confirmDialogOpen}
        title={
          get(formData, 'status') === 'enabled'
            ? t('confirmDisablePatientTracking')
            : t('confirmEnable', { title: formData?.title || '' })
        }
        description={get(formData, 'status') === 'enabled' ? t('disableNotice') : t('enableNotice')}
        confirmText={t('confirm')}
        cancelText={t('cancel')}
        onConfirm={() => handleSubmit({ updateStatus: formData.status === 'enabled' ? 'disabled' : 'enabled' })}
        onCancel={() => setConfirmDialogOpen(false)}
        isLoading={isLoading}
      />
    </Container>
  );
};

const propTypes = {
  mode: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

PatientTrackingForm.propTypes = propTypes;

export default connectData(PatientTrackingForm);
