import React from 'react';
import PropTypes from 'prop-types';
import { ImageContainer, ImageWrap, Imagea, Image, PureImage } from './styledComponents';

function ImageRenderer({ content }) {
  if (content.previewImageUrl) {
    return <PureImage src={content.previewImageUrl}></PureImage>;
  }
  const { aspectRatio = '1:1', url, margin, size, aspectMode, gravity, align } = content;
  const ratio = `${(parseFloat(aspectRatio.split(':')[1]) / parseFloat(aspectRatio.split(':')[0])) * 100}%`;
  return (
    <ImageContainer margin={margin} align={align} gravity={gravity}>
      <ImageWrap size={size}>
        <Imagea ratio={ratio}>
          <Image url={url} aspectMode={aspectMode} />
        </Imagea>
      </ImageWrap>
    </ImageContainer>
  );
}

ImageRenderer.propTypes = {
  content: PropTypes.shape({
    url: PropTypes.string,
    gravity: PropTypes.string,
    margin: PropTypes.string,
    aspectRatio: PropTypes.string,
    aspectMode: PropTypes.string,
    size: PropTypes.string,
    align: PropTypes.string,
  }),
};

export default ImageRenderer;
