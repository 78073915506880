import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Swapper from './Swapper';
import MainKeys from './MainKeys';
import ImageEditor from './ImageEditor';
import TextFieldWithFlexVar from '../../../utilsComponents/TextFieldWithFlexVar';
import { t } from 'i18n/config';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '70%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const Container = styled.div``;
const defaultStyles = {};
function MainKeysEditor({
  mainStyles,
  onAdd,
  onChangeStyles,
  onRemove,
  path,
  content,
  onChange,
  parentPath,
  index,
  onValidColorChange,
  onValidRatio,
}) {
  const classes = useStyles();
  const handleChange = (changes) => {
    onChangeStyles({
      ...changes,
    });
  };

  return (
    <Container>
      <FormControl className={classes.formControl}>
        <Swapper onRemove={onRemove} enableAdd={path !== MainKeys.HERO} onAdd={onAdd} />
      </FormControl>
      {content.type === 'image' && (
        <ImageEditor
          onChange={onChange}
          path={path}
          parentPath={parentPath}
          index={index}
          content={content}
          onValidRatio={onValidRatio}
        />
      )}
      {mainStyles && (
        <TextFieldWithFlexVar
          name="backgroundColor"
          onValidColorChange={onValidColorChange}
          value={(mainStyles || defaultStyles).backgroundColor}
          onChange={handleChange}
          varText="{{myColor}}"
          title={t('flexEditor.backgroundColor')}
        />
      )}
    </Container>
  );
}

MainKeysEditor.propTypes = {
  onAdd: PropTypes.func,
  onChangeStyles: PropTypes.func,
  mainStyles: PropTypes.shape({
    backgroundColor: PropTypes.string,
  }),
  onRemove: PropTypes.func,
  path: PropTypes.string,
  content: PropTypes.shape({
    type: PropTypes.string,
  }),
  onChange: PropTypes.func,
  parentPath: PropTypes.string,
  index: PropTypes.number,
};

export default MainKeysEditor;
