/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  InputGroup,
} from 'reactstrap';
import moment from 'moment';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Spinner from 'react-spinkit';
import { fetchClient, addBlackList } from 'reducers/clients';
import { createCustomer, bulkUpload } from 'reducers/customers';
import { LOCALES } from 'helpers';
import { isAuthRoleBrand } from 'helpers/authHelpers';
import { isValidEmail } from 'helpers/customerHelpers';
import CSVFileUploader from 'components/Header/CSVFileUploader';
import { isValidPhoneNumber } from 'utils/phone';
import Table from './table';
import { t } from 'i18n/config';
import LanguageSwitchButton from 'components/LanguageSwitchButton';

const columns = [
  {
    Header: t('name'),
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: t('mobile'),
    accessor: 'phone',
  },
  {
    Header: t('note'),
    accessor: 'note',
  },
  {
    Header: t('servicePersonnel'),
    accessor: 'serviceProviderName',
  },
  {
    Header: t('nationality'),
    accessor: 'country',
  },
];
const mapStateToProps = (state) => ({
  clients: state.clients,
  accountUser: state.auth.user,
  fbMessageUnreadNumber: state.auth.fbMessageUnreadNumber,
  isFbNotificationEnabled: state.auth.features.fbNotification,
});

const mapDispatchToProps = {
  createCustomer,
  bulkUpload,
  fetchClient,
  addBlackList,
};

@connect(mapStateToProps, mapDispatchToProps)
export default class Header extends Component {
  static propTypes = {
    className: PropTypes.string,
    fbMessageUnreadNumber: PropTypes.number,
    createCustomer: PropTypes.func,
    bulkUpload: PropTypes.func,
    clients: PropTypes.shape({
      byId: PropTypes.object,
      selectedIds: PropTypes.array,
    }),
    accountUser: PropTypes.shape({
      authRole: PropTypes.string,
    }),
    fetchClient: PropTypes.func,
    isFbNotificationEnabled: PropTypes.bool,
    addBlackList: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isShowBulkUpload: false,
      isShowCreateCustomer: false,
      name: '',
      email: '',
      phone: '',
      note: '',
      country: LOCALES.ZH_TW,
      data: [],
      insertTimeStamp: Math.floor(new Date().getTime() / 1000),
      isLoading: false,
      blackListPhone: '',
      blackListEmail: '',
    };
  }

  onCreateCustomer = async (evt) => {
    try {
      evt.preventDefault();
      if (!this.state.isLoading) {
        this.setState({
          isLoading: true,
        });
        const { name, email, phone, note, country } = this.state;
        if (phone === '' && email === '') {
          alert(t('enterEmailOrMobile'));
          this.setState({
            isLoading: false,
          });
          return;
        }
        if (email !== '' && !isValidEmail(email)) {
          alert(t('enterValidEmail'));
          this.setState({
            isLoading: false,
          });
          return;
        }
        if (phone !== '' && !isValidPhoneNumber(phone)) {
          alert(t('enterValidMobile'));
          this.setState({
            isLoading: false,
          });
          return;
        }
        const result = await this.props.createCustomer(name, email, phone, note, country);
        console.log(result);
        this.setState({
          isLoading: false,
        });
        this.toggleCreateCustomer();
      }
      this.props.fetchClient();
    } catch (error) {
      const res = await error;
      alert(res.message);
      this.setState({
        isLoading: false,
      });
      this.toggleCreateCustomer();
    }
  };

  onAddBlackList = () => {
    const { blackListEmail, blackListPhone, blackListName } = this.state;
    const { clients } = this.props;
    const clientInfo = clients.byId[clients.selectedIds[0]];
    this.props.addBlackList({
      clientId: clientInfo.id,
      name: blackListName,
      phone: blackListPhone,
      email: blackListEmail,
    });
    this.toggleBlackList();
  };

  onChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  onFinishedReadCSV = (lines) => {
    this.setState({
      data: lines,
    });
  };

  onChangeDate = (evt) => {
    console.log(moment(evt).unix());
    this.setState({
      insertTimeStamp: moment(evt).unix(),
    });
  };

  onValidateBulkUploadData = () => {
    const { insertTimeStamp, data } = this.state;
    const { clients } = this.props;
    const clientInfo = clients.byId[clients.selectedIds[0]];
    if (data.length === 0) {
      alert(t('uploadCustomerList'));
      return null;
    }

    const delayTime =
      insertTimeStamp < Math.floor(new Date().getTime() / 1000)
        ? 0
        : insertTimeStamp - Math.floor(new Date().getTime() / 1000);
    const body = {
      topic: 'bulkUpload',
      class: 'bulkUpload',
      delayTime,
      client: {
        _cli: clientInfo.id,
        _clia: clientInfo.address,
        _clie: clientInfo.email,
        _cliim: `https://s3-ap-northeast-1.amazonaws.com/alleypin-clients/${clientInfo.imageSlug}`,
        _clin: clientInfo.companyName,
        _clip: clientInfo.phone,
        _cliw: clientInfo.webSite,
      },
      data,
    };
    return body;
  };

  uploadBulkCustomer = async (evt) => {
    const body = this.onValidateBulkUploadData();
    if (!body) {
      return;
    }
    console.log(body);
    evt.preventDefault();
    try {
      if (!this.state.isLoading) {
        this.setState({
          isLoading: true,
        });
        const result = await this.props.bulkUpload(body);
        console.log(result);
        this.setState({
          isLoading: false,
        });
        this.toggleBulkUpload();
      }
      this.props.fetchClient();
    } catch (error) {
      const res = await error;
      alert(res);
      this.setState({
        isLoading: false,
      });
      this.toggleBulkUpload();
    }
  };

  sidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  };

  sidebarMinimize = (e) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  };

  mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  };

  asideToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  };

  clearSingleCreateCustomer = () => {
    this.setState({
      name: '',
      email: '',
      phone: '',
      note: '',
      country: LOCALES.ZH_TW,
    });
  };

  toggleCreateCustomer = () => {
    if (!this.state.isShowCreateCustomer) {
      this.clearSingleCreateCustomer();
    }
    this.setState({
      isShowCreateCustomer: !this.state.isShowCreateCustomer,
    });
  };

  toggleBulkUpload = () => {
    this.setState({
      isShowBulkUpload: !this.state.isShowBulkUpload,
    });
  };

  clearBlackListInput = () => {
    this.setState({
      blackListEmail: '',
      blackListPhone: '',
      blackListName: '',
    });
  };

  toggleBlackList = () => {
    if (!this.state.isShowBlackList) {
      this.clearBlackListInput();
    }
    this.setState({
      isShowBlackList: !this.state.isShowBlackList,
    });
  };

  isMultipleSelectedStores = () => this.props.clients.selectedIds.length > 1;

  // TODO: remove and request a new token for creating single customer flow
  isBrandAccount = () => isAuthRoleBrand(this.props.accountUser);

  renderFBMessageAlertBox = () => {
    const { fbMessageUnreadNumber, clients, isFbNotificationEnabled } = this.props;
    const firstClient = clients.byId[clients.selectedIds[0]];
    const { fbFanPage } = firstClient || { fbFanPage: '' };
    return (
      <Nav className="d-md-down-none" navbar hidden={!isFbNotificationEnabled || !fbMessageUnreadNumber}>
        <NavItem className="px-4">
          <Button outline color="danger">
            <a target="_blank" href={`${fbFanPage}inbox`} style={{ color: '#d9534f' }}>
              {t('unReadMessagesOnFB')}
              {fbMessageUnreadNumber}
              {t('unreadMessagesCount')}
            </a>
          </Button>
        </NavItem>
      </Nav>
    );
  };

  renderButtonTextView = (title) => {
    if (this.state.isLoading) {
      return <Spinner name="circle" color="#fff" />;
    }
    return title;
  };

  render() {
    const { data } = this.state;
    const { clients } = this.props;
    const smsCountLeft = clients.selectedIds
      .map((id) => clients.byId[id])
      .reduce((sum, client) => sum + parseFloat(client.smsCountLeft), 0);
    const linecount = clients.selectedIds
      .map((id) => clients.byId[id])
      .reduce((sum, client) => sum + parseFloat(client.linecount) || 0, 0);
    let displayTitle = '';
    const {
      companyName,
      brands = '',
      vendor = '',
    } = clients.byId[clients.selectedIds[0]] || { companyName: '', brands: '', vendor: '' };
    displayTitle = this.isMultipleSelectedStores() ? `${brands}_chain` : companyName;
    return (
      <header className="app-header navbar">
        <div className="d-flex">
          <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
            <span className="navbar-toggler-icon" />
          </NavbarToggler>
          <NavbarBrand href="/home" />
          <NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>
            <span className="navbar-toggler-icon" />
          </NavbarToggler>
          <Nav className="d-md-down-none" navbar>
            <NavItem className="px-4">
              <NavLink href="#">
                {t('loginAccount')}
                {displayTitle}
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {this.renderFBMessageAlertBox()}
        <Nav className="ml-auto nav-right-container" navbar>
          {/* action buttons */}
          <div className="nav-action-container d-flex d-flex-row">
            <NavItem className="d-md-down-none px-3">
              <Button
                outline
                color="danger"
                onClick={this.toggleBlackList}
                hidden={this.isMultipleSelectedStores() || this.isBrandAccount()}
              >
                {t('addBlacklist')}
              </Button>
            </NavItem>
            <div />
            <Modal
              isOpen={this.state.isShowBlackList}
              toggle={this.toggleBlackList}
              className={`modal-lg ${this.props.className}`}
            >
              <ModalHeader toggle={this.toggleBlackList}>{t('addBlacklist')}</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <InputGroup>
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t('name')}</span>
                    </div>
                    <Input type="text" id="blackListName" name="blackListName" onChange={this.onChange} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="fa fa-user" />
                      </span>
                    </div>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t('mobile')}</span>
                    </div>
                    <Input type="text" id="blackListPhone" name="blackListPhone" onChange={this.onChange} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="fa fa-user" />
                      </span>
                    </div>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <div className="input-group-prepend">
                      <span className="input-group-text">Email</span>
                    </div>
                    <Input type="email" id="blackListEmail" name="blackListEmail" onChange={this.onChange} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="fa fa-at" />
                      </span>
                    </div>
                  </InputGroup>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.onAddBlackList} disabled={this.state.isLoading}>
                  {this.renderButtonTextView(t('addNew'))}
                </Button>
                <Button color="secondary" onClick={this.toggleBlackList}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>

            <NavItem className="px-0">
              <Button
                outline
                color="success"
                onClick={this.toggleCreateCustomer}
                hidden={this.isMultipleSelectedStores() || this.isBrandAccount()}
              >
                {t('enterCustomerList')}
              </Button>
            </NavItem>
            <div />
            <Modal
              isOpen={this.state.isShowCreateCustomer}
              toggle={this.toggleCreateCustomer}
              className={`modal-lg ${this.props.className}`}
            >
              <ModalHeader toggle={this.toggleCreateCustomer}>{t('enterCustomerList')}</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <InputGroup>
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t('name')}</span>
                    </div>
                    <Input type="text" id="name" name="name" onChange={this.onChange} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="fa fa-user" />
                      </span>
                    </div>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <div className="input-group-prepend">
                      <span className="input-group-text">Email</span>
                    </div>
                    <Input type="email" id="email" name="email" onChange={this.onChange} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="fa fa-at" />
                      </span>
                    </div>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t('phone')}</span>
                    </div>
                    <Input type="text" id="phone" name="phone" onChange={this.onChange} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="fa fa-phone" />
                      </span>
                    </div>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t('nationality')}</span>
                    </div>
                    <Input type="select" name="country" onChange={this.onChange}>
                      <option selected value={LOCALES.ZH_TW}>
                        {t('taiwan')}
                      </option>
                      <option value={LOCALES.ZH_HK}>{t('hongKong')}</option>
                      <option value={LOCALES.ZH_CN}>{t('china')}</option>
                      <option value={LOCALES.EN}>{t('europeAmerica')}</option>
                      <option value={LOCALES.JA}>{t('japan')}</option>
                      <option value={LOCALES.OTHER}>{t('other')}</option>
                    </Input>
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="fa fa-flag" />
                      </span>
                    </div>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t('note')}</span>
                    </div>
                    <Input type="text" id="note" name="note" onChange={this.onChange} placeholder={t('optional')} />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="fa fa-file-o" />
                      </span>
                    </div>
                  </InputGroup>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.onCreateCustomer} disabled={this.state.isLoading}>
                  {this.renderButtonTextView(t('addNew'))}
                </Button>{' '}
                <Button color="secondary" onClick={this.toggleCreateCustomer}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
            <NavItem className="d-md-down-none px-3">
              <Button outline color="primary" onClick={this.toggleBulkUpload} hidden={this.isMultipleSelectedStores()}>
                {t('bulkUpload')}
              </Button>
            </NavItem>
          </div>
          {/* sms remain usage & language setting */}
          <div className="nav-setting-container d-flex d-flex-row">
            <NavItem className="d-flex align-items-center d-md-down-none px-3">
              <span style={{ marginRight: '16px' }} hidden={this.isMultipleSelectedStores() || linecount === 0}>
                <i className="fab fa-line" /> {t('thisMonthMessageUsage')}
                {linecount}
              </span>
              <span hidden={this.isMultipleSelectedStores()}>
                <i className="fa fa-phone" />
                {t('remainingSmsQuantity')}
                {smsCountLeft}
              </span>
            </NavItem>
            <Modal
              isOpen={this.state.isShowBulkUpload}
              toggle={this.toggleBulkUpload}
              className={`modal-lg ${this.props.className}`}
            >
              <ModalHeader toggle={this.toggleBulkUpload}>{t('bulkUpload')}</ModalHeader>
              <ModalBody>
                <ol>
                  <li>
                    <div className="text-muted">{t('downloadSampleCsv')}</div>

                    <form method="get" action="https://s3-ap-northeast-1.amazonaws.com/allypin-assets/多筆上傳表格.csv">
                      <Button color="primary" type="submit">
                        <i className="fa fa-apple" /> {t('macUser')}
                      </Button>
                    </form>
                  </li>
                  <li className="d-none d-md-table-cell">
                    <div className="text-muted">---------</div>
                    <form
                      method="get"
                      action="https://s3-ap-northeast-1.amazonaws.com/allypin-assets/%E5%A4%9A%E7%AD%86%E4%B8%8A%E5%82%B3%E8%A1%A8%E6%A0%BC+ANSI.csv"
                    >
                      <Button color="primary" type="submit">
                        <i className="fa fa-windows" /> {t('windowsUser')}
                      </Button>
                    </form>
                  </li>
                  <li>
                    <div className="text-muted">{t('uploadFile')}</div>
                    <i className="fa fa-apple" /> {t('macUser')}
                    <CSVFileUploader platform="apple" vendor={vendor} onFinishedRead={this.onFinishedReadCSV} />
                  </li>
                  <li className="d-none d-md-table-cell">
                    <div className="text-muted">---------</div>
                    <i className="fa fa-windows" /> {t('windowsUser')}
                    <CSVFileUploader platform="windows" vendor={vendor} onFinishedRead={this.onFinishedReadCSV} />
                  </li>
                  <li>
                    <div className="text-muted">{t('chooseTime')}</div>
                    <Datetime onChange={this.onChangeDate} defaultValue={new Date()} />
                  </li>
                </ol>
                <Table columns={columns} data={data} />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={this.uploadBulkCustomer} disabled={this.state.isLoading}>
                  {this.renderButtonTextView(t('startSending'))}
                </Button>{' '}
                <Button color="secondary" onClick={this.toggleBulkUpload}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
            <LanguageSwitchButton />
          </div>
        </Nav>
      </header>
    );
  }
}
