import React, { useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Alert from 'components/Dashboard_v2/Alert';
import styled from 'styled-components';

import { setAlert as _setAlert, clearAlert as _clearAlert } from 'reducers/alert';

const AlertWrapper = styled.div`
  position: ${({ position = 'absolute' }) => position};
  top: ${({ top = '35px' }) => top};
  transform: translateX(-50%);
  left: 50%;
  z-index: 110;
`;

const useAlert = (options = {}) => {
  const { unmountClear = false } = options;
  const dispatch = useDispatch();
  const timeoutRef = useRef();
  const isUnmount = useRef(false);
  const alert = useSelector((state) => state.alert.data);
  const setAlert = ({ type, title, showClear, titleChildren }) =>
    dispatch(_setAlert({ type, title, showClear, titleChildren }));
  const clearAlert = useCallback(() => dispatch(_clearAlert()), [dispatch]);
  const renderAlert = useCallback(
    ({ position, top } = {}) =>
      alert ? (
        <AlertWrapper position={position} top={top}>
          <Alert
            type={alert.type}
            title={alert.title}
            onClose={alert?.showClear === false ? null : clearAlert}
            onAction={alert?.onAction}
            titleChildren={alert?.titleChildren}
          />
        </AlertWrapper>
      ) : null,
    [alert, clearAlert]
  );

  useEffect(() => {
    if (alert) {
      timeoutRef.current = setTimeout(() => clearAlert(), 8000);
    }
  }, [alert, clearAlert]);
  useEffect(() => () => (isUnmount.current = true), []);
  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (isUnmount.current && (unmountClear || (alert && alert.type === 'error'))) {
        clearAlert();
      }
    },
    [alert, unmountClear, clearAlert]
  );

  return {
    alert,
    setAlert,
    clearAlert,
    renderAlert,
  };
};

export default useAlert;
