import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dashboard_v2/Dialog';
import Select from 'components/Dashboard_v2/Select';
import { t } from 'i18n/config';

const CategoryModal = ({ data, onConfirm, onClose }) => {
  const { selectedCategory, categories } = data;
  const [selectedOption, setSelectedOption] = useState(selectedCategory);
  const handleConfirm = () => {
    onConfirm(selectedOption);
    onClose();
  };

  return (
    <Dialog
      open={true}
      title={t('category')}
      description={
        <Select
          label={t('itemName')}
          options={categories}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          showArrow={false}
          allowAdd
          allowSearch
          fullWidth
        />
      }
      confirmText={t('save')}
      cancelText={t('cancel')}
      onConfirm={handleConfirm}
      onCancel={onClose}
      confirmColorType="primary"
    />
  );
};

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

CategoryModal.propTypes = propTypes;

export default memo(CategoryModal);
