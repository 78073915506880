import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash-es';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import {
  getTemplate,
  getGroupMessage,
  createGroupMessage,
  updateGroupMessage,
  sendGroupMessage,
  getGroupingUsers,
} from 'lib/messageCenterService';
import { fetchGroups } from 'lib/memberService';
import connectData from 'components/HOCs/connectData';
import useAlert from 'hooks/useAlert';

import { dashboard_v2 } from 'theme';
import Button from 'components/Dashboard_v2/Button';
import Block from 'components/Dashboard_v2/Block';
import Dialog from 'components/Dashboard_v2/Dialog';

import { conventFormDataToTemplateData, conventTemplateDataToFormData } from '../utils';
import { InfoBlock } from '../components/EditBlocks';
import TitleModal from '../components/TitleModal';
import MessageModal from '../components/MessageModal';
import MessageBubble from '../components/MessageModal/MessageBubble';
import TemplateListModal from '../components/TemplateListModal';
import ScheduleTimeModal, { getDisplayTime } from '../components/ScheduleTimeModal';
import TargetModal from '../components/TargetModal';
import UserListModal from '../components/UserListModal';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Container = styled.div`
  position: relative;
  padding-bottom: 150px;
`;

const Header = styled.div`
  padding: 30px 30px 24px;
  margin: 0 -30px;
  background-color: ${colors.SHADES_000};
  border-bottom: 1px solid ${colors.SHADES_200};
  display: flex;
`;

const FormActions = styled.div`
  margin-left: auto;
  display: flex;
  gap: 0 8px;
`;

const Main = styled.div`
  margin: 30px 0;
`;

const TemplateBlockTitle = styled.div`
  display: flex;
  align-items: center;
  i {
    font-size: 30px;
    color: ${colors.SHADES_400};
    margin-right: 24px;
  }
`;

const TemplateBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 32px 24px;
  border: solid 1px ${colors.SHADES_400};
  border-radius: 8px;
  background-color: ${colors.SHADES_000};
  font-size: 16px;
  font-weight: 700;
`;

const IconButton = styled(Button)`
  font-size: 16px;
  line-height: 1.3;

  i {
    font-size: 14px;
    margin-top: 2px;
    margin-right: 4px;
  }
`;

const MODE_NAME_MAP = {
  create: t('add'),
  edit: t('edit'),
  send: t('send'),
};

const getInitialFormState = (clientId) => ({
  title: '',
  status: 'draft',
  clientId,
  messageTemplateId: null,
  messageTemplateTitle: null,
  isAvoidDuplicated: true,
  toAllMembers: true,
  sendMessageType: 'line',
  scheduleType: 'immediately',
  scheduleTime: null,
  excludedUserIDs: [],
  actionId: null,
  alias: null,
  message: [],
  replies: [],
  groupMessageFilters: {
    groups: [],
    excludedGroups: [],
    appointmentTimes: [],
  },
});

const GroupMessageForm = ({ mode, match }) => {
  const groupMessageId = match.params.id;
  const { renderAlert, setAlert } = useAlert();
  const clientName = useSelector((state) => state.auth.user.companyName);
  const [clientId] = useSelector((state) => state.clients.selectedIds);
  const { openAI: openAIEnabled } = useSelector((state) => state.clients.byId[clientId]?.features);
  const [isInit, setIsInit] = useState(false);
  const [formData, setFormData] = useState(null);
  const [redirectToList, setRedirectToList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editModal, setEditModal] = useState(null);
  const [groupList, setGroupList] = useState([]);
  const [users, setUsers] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const disableEdit = formData && formData.status === 'done';

  const selectedUserIds = useMemo(
    () =>
      formData && users
        ? users
            .filter((user) => !formData.excludedUserIDs.includes(user.id))
            .map((user) => ({ id: user.id, lineUserID: user.lineUserID }))
        : [],
    [users, formData]
  );

  const disabledSubmit = useMemo(() => {
    if (!formData) {
      return true;
    }
    const { title, message, scheduleType } = formData;
    const isEmptyTitle = !title;
    const isEmptyMessage = !message.length;
    const isEmptyUserIds = scheduleType === 'immediately' && !selectedUserIds.length;
    return isEmptyUserIds || isEmptyTitle || isEmptyMessage;
  }, [formData, selectedUserIds]);

  const handleModalOpen = (type) => {
    setEditModal(type);
  };

  const handleModalClose = () => {
    setEditModal(null);
  };

  const handleFormDataChange = useCallback((data) => setFormData((prev) => ({ ...prev, ...data })), []);

  const handleTargetChange = useCallback(({ targetData, users }) => {
    setFormData((prev) => ({ ...prev, ...targetData }));
    setUsers(users);
  }, []);

  const fetchGroupingUsers = useCallback(
    async (formData) => {
      try {
        const { toAllMembers, groupMessageFilters } = formData;
        const groupId = get(groupMessageFilters, 'groups[0].id');
        const excludedGroupId = get(groupMessageFilters, 'excludedGroups[0].id');
        const appointmentTime = get(groupMessageFilters, 'appointmentTimes[0]');
        const payload = {
          toAllMembers,
          groupIDs: groupId ? [groupId] : [],
          excludedGroupIDs: excludedGroupId ? [excludedGroupId] : [],
        };
        if (appointmentTime) {
          Object.assign(payload, {
            startTime: appointmentTime.startTime,
            endTime: appointmentTime.endTime,
          });
        }
        const { users } = await getGroupingUsers({ clientId, payload });
        setUsers(() => users);
      } catch (e) {
        const errRes = await e;
        console.error(errRes);
      }
    },
    [clientId]
  );

  const handleTemplateChange = useCallback(
    async (templateId) => {
      if (templateId === 'empty') {
        const newFormData = {
          ...getInitialFormState(clientId),
          messageTemplateId: null,
          messageTemplateTitle: t('blankTemplate'),
        };
        setFormData(() => ({
          ...newFormData,
          messageTemplateId: null,
          messageTemplateTitle: t('blankTemplate'),
        }));
        await fetchGroupingUsers(newFormData);
        handleModalClose();
      } else {
        try {
          const data = await getTemplate({ clientId, templateId });
          const formattedTemplateData = conventTemplateDataToFormData({ templateType: 'groupMessage', data });
          const templateData = {
            messageTemplateId: formattedTemplateData.id,
            messageTemplateTitle: formattedTemplateData.title,
            message: formattedTemplateData.message,
            replies: formattedTemplateData.replies,
          };
          setFormData((prev) => ({
            ...prev,
            ...templateData,
          }));
          handleModalClose();
        } catch (e) {
          const errRes = await e;
          const { error } = errRes;
          setAlert({ type: 'error', title: t('loadTemplateFailed', { error: error || e.message }) });
          console.error(errRes);
        }
      }
    },
    [clientId, setAlert, fetchGroupingUsers]
  );

  const handleSubmit = async ({ submitType }) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      let createdId = null;
      const payload = conventFormDataToTemplateData({ templateType: 'groupMessage', formData, mode });
      payload.usersCount = formData.scheduleType === 'immediately' ? selectedUserIds.length : users.length;
      if (mode === 'create') {
        const { id } = await createGroupMessage({ clientId, payload });
        createdId = id;
      }
      if (mode === 'edit') {
        await updateGroupMessage({ clientId, groupMessageId, payload });
      }
      if (submitType === 'send' || formData.status === 'pending') {
        await sendGroupMessage({
          clientId,
          groupMessageId: mode === 'create' ? createdId : groupMessageId,
          payload: {
            userIDs: formData.scheduleType === 'immediately' ? selectedUserIds : [],
          },
        });
      }
      setAlert({ type: 'success', title: t('actionSuccess', { type: MODE_NAME_MAP[submitType || mode] }) });
      setRedirectToList(true);
    } catch (e) {
      const errRes = await e;
      const { error } = errRes;
      setAlert({ type: 'error', title: error || t('actionFailed', { type: MODE_NAME_MAP[submitType || mode] }) });
      console.error(errRes);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const setup = async () => {
      try {
        const { groups } = await fetchGroups({ clientId });
        setGroupList(groups);

        if (mode === 'create') {
          setFormData(getInitialFormState());
        }

        if (mode === 'edit') {
          const getGroupMessageData = async () => {
            const data = await getGroupMessage({ clientId, groupMessageId });
            const conventedData = conventTemplateDataToFormData({ templateType: 'groupMessage', data });
            if (!conventedData.messageTemplateTitle) {
              conventedData.messageTemplateTitle = t('blankTemplate');
            }
            setFormData(conventedData);
          };
          getGroupMessageData();
        }
      } catch (e) {
        const errRes = await e;
        const { error } = errRes;
        setAlert({ type: 'error', title: t('loadDataFailed', { error: error || e.message }) });
        console.error(errRes);
      }
    };
    setup();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!formData || isInit) return;
    fetchGroupingUsers(formData);
    setIsInit(true);
  }, [formData, mode, isInit, fetchGroupingUsers]);

  return redirectToList ? (
    <Redirect push to="/message_center/group_message" />
  ) : (
    <Container>
      {renderAlert()}
      <Header>
        <Link to="/message_center/group_message">
          <IconButton color="secondary" variant="outline">
            <i className="ri-arrow-left-line" />
            {t('goBack')}
          </IconButton>
        </Link>
        <FormActions>
          {formData && formData.status === 'draft' && (
            <React.Fragment>
              <Button color="secondary" variant="outline" onClick={handleSubmit} disabled={disabledSubmit}>
                {isLoading ? <CircularProgress style={{ color: colors.PRIMARY_400 }} size={20} /> : t('saveDraft')}
              </Button>
              <IconButton
                color="primary"
                variant="filled"
                onClick={() => setConfirmDialogOpen(true)}
                disabled={disabledSubmit}
              >
                <i className="ri-mail-send-line" />
                {t('publishMessage')}
              </IconButton>
            </React.Fragment>
          )}
          {formData && formData.status === 'pending' && (
            <Button
              color="primary"
              variant="filled"
              onClick={() => setConfirmDialogOpen(true)}
              disabled={disabledSubmit}
            >
              {isLoading ? <CircularProgress style={{ color: colors.PRIMARY_400 }} size={20} /> : t('update')}
            </Button>
          )}
        </FormActions>
      </Header>
      {formData && (
        <Main>
          {mode === 'create' && !formData.messageTemplateTitle && (
            <Block
              title={
                <TemplateBlockTitle>
                  <i className="ri-archive-fill" />
                  {t('groupMessage')}
                </TemplateBlockTitle>
              }
            >
              <TemplateBlock>
                {t('groupMessageTemplate')}
                <Button
                  variant="outline"
                  color="secondary"
                  onClick={() => handleModalOpen('template')}
                  disabled={disableEdit}
                >
                  {t('chooseTemplate')}
                </Button>
              </TemplateBlock>
            </Block>
          )}
          {formData.messageTemplateTitle && (
            <React.Fragment>
              {/* Base Info */}
              <Block title={t('basicSettingTitle')}>
                <InfoBlock
                  contents={[{ title: t('itemName'), value: formData.title, placeholder: t('nameExample') }]}
                  onEditButtonClick={() => handleModalOpen('title')}
                  hiddenButton={disableEdit}
                />
                <InfoBlock
                  contents={[
                    {
                      title: t('messageTemplate'),
                      value: formData.messageTemplateTitle || t('blankTemplate'),
                      placeholder: t('messageTemplateExample'),
                    },
                  ]}
                  onEditButtonClick={() => handleModalOpen('template')}
                  hiddenButton={disableEdit}
                />
                <InfoBlock
                  contents={[
                    {
                      title: t('previewMessage'),
                      value: formData.message.length
                        ? formData.message.map((message, index) => (
                            <MessageBubble clientName={clientName} data={message} key={index} />
                          ))
                        : null,
                      placeholder: t('noMessageEdited'),
                    },
                  ]}
                  onEditButtonClick={() => handleModalOpen('message')}
                  flexColumn={formData.message.length > 0}
                  viewMode={disableEdit}
                />
              </Block>
              <Block title={t('setSendingCondition')}>
                <InfoBlock
                  contents={[
                    {
                      title: t('chooseSendingTarget'),
                      value: (() => {
                        const { toAllMembers, groupMessageFilters } = formData;
                        if (toAllMembers) return t('allMembers');
                        const group = get(groupMessageFilters, 'groups[0]');
                        const excludedGroup = get(groupMessageFilters, 'excludedGroups[0]');
                        const appointmentTime = get(groupMessageFilters, 'appointmentTimes[0]');
                        return group || appointmentTime ? (
                          <React.Fragment>
                            {group ? (
                              <div>{`${group.name}${
                                excludedGroup ? `/${t('excluded')}: ${excludedGroup.name}` : ''
                              }`}</div>
                            ) : null}
                            {appointmentTime ? (
                              <div>{`${appointmentTime.startTime} ~ ${appointmentTime.endTime} `}</div>
                            ) : null}
                          </React.Fragment>
                        ) : null;
                      })(),
                      placeholder: t('notChosenTarget'),
                    },
                    {
                      title: t('sendingMethod'),
                      value: formData.sendMessageType.toUpperCase(),
                      placeholder: t('notChosenMethod'),
                    },
                    {
                      title: t('mergeSendingMessage'),
                      value: formData.isAvoidDuplicated ? t('open') : t('close'),
                      placeholder: t('none'),
                    },
                    {
                      title: t('sendingQuantity'),
                      value: users
                        ? `
                          ${formData.scheduleType === 'immediately' ? `${selectedUserIds.length}/` : ''}
                          ${users.length}`
                        : null,
                      placeholder: t('none'),
                    },
                  ]}
                  onEditButtonClick={() => handleModalOpen('target')}
                  hiddenButton={disableEdit}
                />
              </Block>
              <Block title={t('setSendingTime')}>
                <InfoBlock
                  contents={[
                    {
                      title: t('sendingTime'),
                      value:
                        formData.scheduleType === 'immediately'
                          ? t('sendImmediately')
                          : formData.scheduleTime
                          ? getDisplayTime(formData.scheduleTime)
                          : null,
                      placeholder: t('notChosenSendingTime'),
                    },
                  ]}
                  onEditButtonClick={() => handleModalOpen('schedule')}
                  hiddenButton={disableEdit}
                />
              </Block>
              <Block title={t('viewSendingList')}>
                <InfoBlock
                  contents={[
                    {
                      title: t('sendingList'),
                      value:
                        formData.scheduleType === 'immediately'
                          ? t('selectedPeopleCount', { count: selectedUserIds.length })
                          : t('listWillBeGenerated'),
                      placeholder: t('none'),
                    },
                  ]}
                  onEditButtonClick={() => handleModalOpen('userList')}
                  disabled={formData.scheduleType !== 'immediately' || selectedUserIds.length === 0}
                  viewMode={disableEdit && formData.scheduleType === 'immediately'}
                  hiddenButton={disableEdit && formData.scheduleType !== 'immediately'}
                />
              </Block>
            </React.Fragment>
          )}
        </Main>
      )}
      {/* Edit Modals */}
      {editModal === 'template' && (
        <TemplateListModal
          templateType="groupMessage"
          selectedId={formData.messageTemplateId}
          onSelected={(id) => handleTemplateChange(id)}
          onClose={handleModalClose}
        />
      )}
      {editModal === 'title' && (
        <TitleModal
          data={formData.title}
          onConfirm={(title) => handleFormDataChange({ title })}
          onClose={handleModalClose}
        />
      )}
      {editModal === 'message' && (
        <MessageModal
          data={{
            alias: formData.alias,
            message: formData.message,
            replies: formData.replies,
          }}
          hideAlias={true}
          clientName={clientName}
          clientId={clientId}
          openAIEnabled={openAIEnabled}
          onConfirm={handleFormDataChange}
          onClose={handleModalClose}
          viewMode={disableEdit}
        />
      )}
      {editModal === 'target' && (
        <TargetModal
          clientId={clientId}
          groupList={groupList}
          data={{
            toAllMembers: formData.toAllMembers,
            groupMessageFilters: formData.groupMessageFilters,
            isAvoidDuplicated: formData.isAvoidDuplicated,
          }}
          onConfirm={handleTargetChange}
          onClose={handleModalClose}
        />
      )}
      {editModal === 'schedule' && (
        <ScheduleTimeModal
          data={{
            scheduleType: formData.scheduleType,
            scheduleTime: formData.scheduleTime,
          }}
          onConfirm={handleFormDataChange}
          onClose={handleModalClose}
        />
      )}
      {editModal === 'userList' && (
        <UserListModal
          data={{ users, selectedUserIds }}
          onConfirm={handleFormDataChange}
          onClose={handleModalClose}
          viewMode={disableEdit}
        />
      )}
      {formData && (
        <Dialog
          open={confirmDialogOpen}
          title={formData.status === 'pending' ? t('confirmUpdateSetting') : t('confirmSendMessage')}
          description={
            formData.scheduleType === 'immediately'
              ? t('willSendToPeople', { count: selectedUserIds.length })
              : getDisplayTime(formData.scheduleTime)
          }
          confirmText={t('confirm')}
          cancelText={t('cancel')}
          onConfirm={() => handleSubmit({ submitType: 'send' })}
          onCancel={() => setConfirmDialogOpen(false)}
          isLoading={isLoading}
        />
      )}
    </Container>
  );
};

const propTypes = {
  mode: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

GroupMessageForm.propTypes = propTypes;

export default connectData(GroupMessageForm);
