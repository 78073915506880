import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Collapse from '@material-ui/core/Collapse';

const Container = styled.div`
  margin: 8px;
  background: #f5f6fa;
`;

const Actions = styled.div`
  background: ${(props) => (props.selected ? '#d0d0d0' : 'unset')};
  display: flex;
  &:hover {
    background: #d0d0d0;
  }
`;

const Row = styled.button`
  flex: 1;
  text-align: left;
  background: transparent;
  border: 0;
  font-size: 1rem;
`;

function CollapseContainer({ title, children, onSelect, content, path, parentPath, selectedPath, index }) {
  const [check, setCheck] = useState(false);
  const handleChange = () => {
    setCheck((checkin) => !checkin);
  };
  const onClickRef = () => {
    onSelect({
      content,
      path,
      parentPath,
      index,
    });
  };
  if (content.type !== 'text' && content.type !== 'span' && content.type !== 'image' && content.type !== 'button') {
    return (
      <Container>
        <Actions selected={path === selectedPath}>
          {check && <ArrowDropDownIcon onClick={handleChange} />}
          {!check && <ArrowRightIcon onClick={handleChange} />}
          <Row onClick={onClickRef}>{title}</Row>
        </Actions>
        <Collapse in={check}>{children}</Collapse>
      </Container>
    );
  } else {
    return (
      <Container>
        <Actions selected={path === selectedPath}>
          <Row onClick={onClickRef}>{title}</Row>
        </Actions>
        <Collapse in={check}>{children}</Collapse>
      </Container>
    );
  }
}

CollapseContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  onSelect: PropTypes.func,
  path: PropTypes.string,
  content: PropTypes.shape({}),
  index: PropTypes.number,
  parentPath: PropTypes.string,
  selectedPath: PropTypes.string,
};

export default CollapseContainer;
