import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getIcons, ICON_TYPES } from 'components/icons';
import connectData from 'components/HOCs/connectData';
import { dashboard_v2 } from 'theme';
import Text from 'components/Dashboard_v2/Text';
import { t } from 'i18n/config';

const { colors } = dashboard_v2;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1168px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;
  padding: 48px 0px;
  gap: 80px;
  .infos-block {
    width: 100%;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    .block {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
`;

const Card = styled.div`
  display: flex;
  padding: 32px;
  gap: 24px;
  box-sizing: border-box;
  background-color: ${colors.SHADES_000};
  border-radius: 16px;
  align-items: center;
  .icon {
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.SHADES_100};
    border-radius: 50%;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    ${dashboard_v2.fonts['Body/16px_Medium']}
  }
`;

const Home = ({ newInfos }) => {
  return (
    <Container>
      <Text font="Heading/H4/Heavy" color={colors.SHADES_800}>
        {t('home.title')}
      </Text>
      <div className="infos-block">
        <div className="block">
          <Text font="Heading/H5/Heavy" color={colors.SHADES_800}>
            {t('home.newInformation.title')}
          </Text>
          {Array.isArray(newInfos) &&
            newInfos.map((info) => (
              <Card key={info?.id}>
                <div className="icon">
                  {getIcons(ICON_TYPES.RiNotificationFill, { size: 32, color: colors.SHADES_900 })}
                </div>
                <div className="content" dangerouslySetInnerHTML={{ __html: info?.message }} />
              </Card>
            ))}
        </div>
        <div className="block">
          <Text font="Heading/H5/Heavy" color={colors.SHADES_800}>
            {t('home.FAQ.title')}
          </Text>
          <Card>
            <div className="icon">
              {getIcons(ICON_TYPES.RiQuestionnaireFill, { size: 32, color: colors.SHADES_900 })}
            </div>
            <div className="content">
              <Text font="Heading/Large/Heavy">{t('home.FAQ.card.title')}</Text>
              <a href="https://alleypin.gitbook.io/yi-dian-tong-faq/hui-yuan-guan-li/hui-yuan-jie-mian-jie-shao">
                {t('home.FAQ.card.link')}
              </a>
            </div>
          </Card>
        </div>
      </div>
    </Container>
  );
};

const propTypes = {
  newInfos: PropTypes.array,
};

Home.propTypes = propTypes;

export default connectData(Home);
