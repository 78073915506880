import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Swapper from './Swapper';
import { buttonHeightOptions } from '../../templates/styledComponents';
import TextFieldWithFlexVar from '../../../utilsComponents/TextFieldWithFlexVar';
import { t } from 'i18n/config';

const buttonStyleOptions = ['link', 'primary', 'secondary'];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '70%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const Container = styled.div`
  > div {
    width: 85%;
  }
`;

function ButtonEditor({
  onSwapUp,
  onSwapDown,
  onChange,
  path,
  content,
  onRemove,
  parentPath,
  index,
  onValidColorChange,
  onTextEmptyStatus,
}) {
  const classes = useStyles();
  const handleChange = (e) => {
    onChange({
      path,
      content: {
        ...content,
        [e.target.name]: e.target.value,
      },
      index,
      parentPath,
    });
  };

  const handleFlexVarChange = (changes) => {
    if (Object.keys(changes).includes('label')) {
      onChange({
        path,
        content: {
          ...content,
          action: {
            ...content.action,
            ...changes,
          },
        },
        index,
        parentPath,
      });
    } else
      onChange({
        path,
        content: {
          ...content,
          ...changes,
        },
        index,
        parentPath,
      });
  };
  return (
    <Container>
      <FormControl className={classes.formControl}>
        <Swapper onSwapDown={onSwapDown} onSwapUp={onSwapUp} onRemove={onRemove} />
      </FormControl>
      <TextFieldWithFlexVar
        onTextEmptyStatus={onTextEmptyStatus}
        name="label"
        value={content.action.label}
        onChange={handleFlexVarChange}
        title={t('flexEditor.label')}
      />
      <TextFieldWithFlexVar
        name="color"
        value={content.color}
        onChange={handleFlexVarChange}
        varText="{{myColor}}"
        title={t('color')}
        onValidColorChange={onValidColorChange}
      />
      <FormControl className={classes.formControl}>
        <InputLabel id="height"> {t('flexEditor.height')}</InputLabel>
        <Select name="height" labelId="height" id="height-select" value={content.height} onChange={handleChange}>
          {buttonHeightOptions.map((op) => (
            <MenuItem key={op} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="style">{t('flexEditor.style')}</InputLabel>
        <Select name="style" labelId="style" id="style-select" value={content.style} onChange={handleChange}>
          {buttonStyleOptions.map((op) => (
            <MenuItem key={op} value={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Container>
  );
}

ButtonEditor.propTypes = {
  path: PropTypes.string,
  content: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.string,
    align: PropTypes.string,
    weight: PropTypes.string,
    size: PropTypes.string,
    flex: PropTypes.string,
  }),
  parentPath: PropTypes.string,
  index: PropTypes.number,
  onChange: PropTypes.func,
  onSwapUp: PropTypes.func,
  onSwapDown: PropTypes.func,
  onRemove: PropTypes.func,
  onValidColorChange: PropTypes.func,
  onTextEmptyStatus: PropTypes.func,
};

export default ButtonEditor;
